import { GET_ALL_BID_AUCTIONS, GET_ALL_CATEGORIES, GET_ALL_PAST_AUCTIONS, GET_ALL_RUNNING_AUCTIONS, GET_ALL_USERS, GET_BADGE_LISTS, GET_BID_TOTAL, GET_ITEM_LISTING, GET_ITEM_TOTAL, GET_PAST_TOTAL, GET_RUNNING_TOTAL, GET_TOTAL_CATEGORIES, GET_TOTAL_USERS, VIEW_ITEM_DETAIL } from "./userTypes";

const initialState = {
  allUsers: [],
  totalUsers: 0,
  itemdetail: null,
  itemdata: [],
  pastdata: [],
  runningdata: [],
  itemTotal: 0,
  runningTotal: 0,
  pastTotal: 0,
  biddata: [],
  bidtotal: 0,
  categoriesList: [],
  totalCategories: 0,
  badgeLists: []
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case GET_TOTAL_USERS:
      return {
        ...state,
        totalUsers: action.payload,
      };
    case VIEW_ITEM_DETAIL:
      return {
        ...state,
        itemdetail: action.payload,
      };
    case GET_ITEM_LISTING:
      return {
        ...state,
        itemdata: action.payload,
      };
    case GET_ITEM_TOTAL:
      return {
        ...state,
        itemTotal: action.payload,
      };
    case GET_ALL_PAST_AUCTIONS:
      return {
        ...state,
        pastdata: action.payload,
      };
    case GET_PAST_TOTAL:
      return {
        ...state,
        pastTotal: action.payload,
      };
    case GET_ALL_RUNNING_AUCTIONS:
      return {
        ...state,
        runningdata: action.payload,
      };
    case GET_RUNNING_TOTAL:
      return {
        ...state,
        runningTotal: action.payload,
      };
    case GET_ALL_BID_AUCTIONS:
      return {
        ...state,
        biddata: action.payload,
      };
    case GET_BID_TOTAL:
      return {
        ...state,
        bidtotal: action.payload,
      };
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categoriesList: action.payload,
      };
    case GET_TOTAL_CATEGORIES:
      return {
        ...state,
        totalCategories: action.payload,
      };
    case GET_BADGE_LISTS:
      return {
        ...state,
        badgeLists: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
