import React, { useEffect, useState } from "react";
import { Table, Avatar, Button } from "@mui/joy";
import { get_running_auctions } from "../reduxData/user/userAction";
import EmptyList from "../Shared/EmptyList";
import { doc, onSnapshot, updateDoc } from "@firebase/firestore";
import { db } from "../firebase";
import { connect, useDispatch } from "react-redux";
import CustomPagination from "../components/CustomPagination";
import ViewDetail from "../Modals/ViewDetail";
import { VIEW_ITEM_DETAIL } from "../reduxData/user/userTypes";
import moment from "moment";
import { format } from "date-fns";
import EmptyData from "../components/EmptyData";
import BidTimer from "../components/BidTimer";

const RunningAuctions = ({runningdata,runningTotal}) => {
  // Dummy data for item listings
  const [itemData, setItemData] = useState(null);
  const [isPop, setIsPop] = useState(false);
  const dispatch = useDispatch();
  const { REACT_APP_BASE_URL } = process.env;

  useEffect(() => {
    // get_running_auctions()

    const fetchData = async () => {
      try {
        await get_running_auctions(dispatch,localStorage.getItem("token"),1,10);
        // console.log(data?.data?.data);
        // setItemData(data?.data?.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const userRef = doc(db,'admin','ADMIN');
    const getItem = onSnapshot(userRef,(snapshot) => {
      if(snapshot.exists()){
        const newitem = snapshot.data();
        if(newitem.bid_place){
          handleUpdate();
        }
      }
    })
  },[]);

  const handleUpdate = async() => {
    const userRef = doc(db,'admin','ADMIN');
    await get_running_auctions(dispatch,localStorage.getItem("token"),1,10);
    await updateDoc(userRef, { bid_place: false });
  };
  
  const handleprice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const USDFormat = (num) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(num);
  };


  return (
    <div className="container mt-3">
      {/* <h3 className="mb-0 fw-600">Active Auctions</h3> */}
      <div className="table-responsive mt-4">
        <table className="table user-management-table table-hover">
          <thead className="border-gray">
          <th>Thumbnail</th>
          <th>Price</th>
          <th>Item Modal Name</th>
          <th>Owned By Name</th>
          <th>Country & Zip</th>
          <th>Highest Price</th>
          <th>Total Bids</th>
          <th>Bid End Time</th>
        </thead>
        <tbody>
          {runningTotal > 0 ?
            runningdata.map((item, index) => (
              <tr key={index} className="cursor-pointer" onClick={() => { setIsPop(true); dispatch({ type: VIEW_ITEM_DETAIL, payload: item }); }}>
                <td>
                  <Avatar
                    src={`${REACT_APP_BASE_URL}` + item.photos[0]}
                    alt={`Item Thumbnail ${index + 1}`}
                  />
                </td>
                <td>{item.reserver_price !== 'null' && item?.reserve_price !== '0' ? USDFormat(item.reserver_price) : '$0'}</td>
                <td>{item.item_name}</td>
                <td>{item.owner.full_name}</td>
                <td>{item.owner_country}({item?.zipcode})</td>
                <td>{item.bid_price ? USDFormat(item.bid_price) : '$0'}</td>
                <td>{item.bidCount}</td>
                {/* <td>
                  <Button variant="contained" color="primary">
                    {item.totalBids}
                  </Button>
                </td> */}
                <td>
                  <BidTimer start={item?.start_date} end={item?.end_date} />
                  {/* {format(new Date(item?.end_date),'dd/MM/yyyy')} */}
                </td>
              </tr>
            )) : 
            <EmptyData />
            // <EmptyList name="Active Auctions" />
            }
          </tbody>
        </table>
      </div>
      <div className="mt-3">
        {runningTotal > 0 && (
          <CustomPagination
            total={runningTotal}
            onPageChange={(page, perPage) => {  get_running_auctions(dispatch, localStorage.getItem("token"), page, perPage); }}
          />
        )}
      </div>
      <ViewDetail show={isPop} handleClose={() => { setIsPop(false); dispatch({ type: VIEW_ITEM_DETAIL, payload: null }); }} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    runningdata: state.user.runningdata,
    runningTotal: state.user.runningTotal
  }
}
export default connect(mapStateToProps)(RunningAuctions);
