import React, { useEffect, useRef, useState } from "react";
import { Table, Avatar, Button } from "@mui/joy";
import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import {
  approve_auction,
  get_all_sell_Item,
  get_category,
  get_past_auctions,
  get_running_auctions,
} from "../reduxData/user/userAction";
import { Link, useNavigate } from "react-router-dom";
import ViewDetail from "../Modals/ViewDetail";
import { connect, useDispatch } from "react-redux";
import { VIEW_ITEM_DETAIL } from "../reduxData/user/userTypes";
import EmptyList from "../Shared/EmptyList";
import AprroveItem from "../Modals/AprroveItem";
import RejectItem from "../Modals/RejectItem";
import { doc, onSnapshot, updateDoc } from "@firebase/firestore";
import { db } from "../firebase";
import RunningAuctions from "./RunningAuctions";
import PastAuction from "./PastAuction";
import ReactSelect from "react-select";
import CustomPagination from "../components/CustomPagination";
import EmptyData from "../components/EmptyData";
// import {MoreVert} from '@material-ui/icons';

const ItemListing = ({ itemdata, itemTotal, pastTotal, runningTotal, categoriesList }) => {
  const [itemData, setItemData] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("pending");
  const [isPop, setIsPop] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [showAction, setShowAction] = useState(false)
  const [showapprove, setShowapprove] = useState(false);
  const [showreject, setShowreject] = useState(false);
  const [item, setItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actionRef = useRef();
  const { REACT_APP_BASE_URL } = process.env;
  useEffect(() => {
    fetchData();
  }, [currentPage, perPage, currentTab]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionRef.current && !actionRef.current.contains(event.target)) {
        setShowAction(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAction]);

  const fetchData = async () => {
    try {
      const queryParams = selectedCategory.map((cat, index) => `filter[${index}]=${cat.value}`).join('&');
      if (currentTab === "pending" || currentTab === "approved" || currentTab === "disapproved") {
        const data = await get_all_sell_Item(localStorage.getItem("token"),dispatch,1,10,currentTab,queryParams);
        setItemData(data?.data?.data);
      } else if (currentTab === "actives") {
        await get_running_auctions(dispatch, localStorage.getItem("token"),1,10, queryParams);
      } else if (currentTab === "past") {
        await get_past_auctions(dispatch, localStorage.getItem("token"), 1, 10, queryParams);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const changeItemStatus = async (id, status) => {
    if (status === true) {
      setShowapprove(true);
    } else if (status === false) {
      setShowreject(true);
    }
    // let data = await approve_auction(id, status, navigate, localStorage.getItem('token'));
    // if (data?.data?.status) {
    //   fetchData();
    // }
  };

  const changeDOJFormat = (data) => {
    const joinDate = new Date(data);
    const formattedJoinDate = joinDate.toLocaleDateString();
    return formattedJoinDate
  };

  useEffect(() => {
    const userRef = doc(db, 'admin', 'ADMIN');
    const getItem = onSnapshot(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const newitem = snapshot.data();
        if (newitem.item_created) {
          handleUpdate();
        }
      }
    })
  }, []);

  const handleUpdate = async () => {
    const userRef = doc(db, 'admin', 'ADMIN');
    await get_all_sell_Item(localStorage.getItem("token"), dispatch, 1, 10,currentPage);
    await updateDoc(userRef, { item_created: false });
  };

  useEffect(() => {
    const handleData = async () => {
      const alldata = await get_category(dispatch,localStorage.getItem('token'),1,10);
      setOptions(alldata?.data?.data?.map((item) => {
        return {
          label: item?.name,
          value: item?._id
        }
      }));
    };
    handleData();
  }, []);

  const handleChange = async (options) => {
    console.log("selectedd..", options);
    setSelectedCategory(options);
    const queryParams = options.map((cat, index) => `filter[${index}]=${cat.value}`).join('&');
    console.log("queryParams", queryParams);
    if (currentTab === "pending" || currentTab === "approved" || currentTab === "disapproved") {
      const data = await get_all_sell_Item(localStorage.getItem("token"), dispatch, 1, 10,currentTab, queryParams);
      setItemData(data?.data?.data);
    } else if (currentTab === "actives") {
      await get_running_auctions(dispatch, localStorage.getItem("token"), 1, 10, queryParams);
    } else if (currentTab === "past") {
      await get_past_auctions(dispatch, localStorage.getItem("token"), 1, 10, queryParams);
    }
  };

  const handleprice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const USDFormat = (num) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(num);
  };


  return (
    <div>
      <div className="container">
        <h3 className="mb-0 fw-600">Auctions</h3>
        <div className="col-lg-8 col-md-8 mt-4">
          <div className="text-md-end">
            <ul className="list-inline mb-0 sorting-list">
              <li className="list-inline-item mb-2 mb-md-0">
                <Link
                  className={`text-decoration-none border rounded-pill px-3 py-2 ${currentTab === 'pending' ? 'active' : ''}`}
                  onClick={() => { setCurrentTab("pending"); setCurrentPage(1); }}
                >
                  Pending
                </Link>
              </li>
              <li className="list-inline-item mb-2 mb-md-0">
                <Link
                  className={`text-decoration-none border rounded-pill px-3 py-2 ${currentTab === 'approved' ? 'active' : ''}`}
                  onClick={() => { setCurrentTab("approved"); setCurrentPage(1); }}
                >
                  Approved
                </Link>
              </li>
              <li className="list-inline-item mb-2 mb-md-0">
                <Link
                  className={`text-decoration-none border rounded-pill px-3 py-2 ${currentTab === 'disapproved' ? 'active' : ''}`}
                  onClick={() => { setCurrentTab("disapproved"); setCurrentPage(1); }}
                >
                  Disapproved
                </Link>
              </li>
              <li className="list-inline-item mb-2 mb-md-0">
                <Link
                  className={`text-decoration-none border rounded-pill px-3 py-2 ${currentTab === 'actives' ? 'active' : ''}`}
                  onClick={() => { setCurrentTab("actives"); setCurrentPage(1); }}
                >
                  Active
                </Link>
              </li>
              <li className="list-inline-item mb-2 mb-md-0">
                <Link
                  className={`text-decoration-none border rounded-pill px-3 py-2 ${currentTab === 'past' ? 'active' : ''}`}
                  onClick={() => { setCurrentTab("past"); setCurrentPage(1); }}
                >
                  Past
                </Link>
              </li>
            </ul>
          </div>{" "}
        </div>
        <div className="col-lg-3 pt-4">
          <ReactSelect
            isMulti={true}
            options={options}
            value={selectedCategory}
            onChange={(selected) => handleChange(selected)}
            placeholder="Select Categories"
          />
        </div>
        <div className="col-lg-5">
          <h5 className="mt-4 fw-600">
            Total
            {`${currentTab === "pending" ? `${' Pending '}` : currentTab === "approved" ? `${' Approved '}` : currentTab === "disapproved" ? `${' Disapproved '}` : currentTab === "actives" ? `${' Active '}` : ' Past '}`}
            Auctions ({`${currentTab === "pending" || currentTab === "approved" || currentTab === "disapproved" ? itemTotal : currentTab === "actives" ? runningTotal : pastTotal}`})
          </h5>
        </div>
        {currentTab === "pending" || currentTab === "approved" || currentTab === "disapproved" ?
          <div className="table-responsive mt-4">
            <table className="table table-hover user-management-table">
              <thead className="border-gray">
                <th>Thumbnail</th>
                <th>Bid Date</th>
                <th>Price</th>
                <th>Item Name</th>
                <th>Owned By</th>
                <th>Country & State</th>
                <th>Type</th>
                <th>Detail Page</th>
                {currentTab === 'pending' && <th className="text-center">Action</th>}
              </thead>
              <tbody>
                {itemdata?.length > 0 ?
                  itemdata.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <Avatar
                            variant="soft"
                            src={`${REACT_APP_BASE_URL}${item.photos[0]}`}
                            alt={`Item Thumbnail ${index + 1}`}
                          />
                          {/* <Chip  color={item.adadmin_approval ? "success":"danger"} >{item.adadmin_approval ? "Approved":"Disapproved"}</Chip> */}
                        </td>
                        <td>{item?.start_date !== null ? changeDOJFormat(item?.start_date) : 'No Date'}</td>
                        <td className="text-center">{item?.reserver_price !== "null" && item?.reserver_price !== '0' ? USDFormat(item?.reserver_price) : '$0'}</td>
                        <td className="cursor-pointer" onClick={() => { setIsPop(true); dispatch({ type: VIEW_ITEM_DETAIL, payload: item }); }}>
                          {item?.item_name}
                        </td>
                        <td>{item?.owner?.full_name}</td>
                        <td>{`${item?.owner_country}, ${item?.owner_state}`}</td>
                        <td>{item?.owner?.store_name ? "Store" : "Private"}</td>
                        <td>
                          <Button
                            className="p-0 text-decoration-underline "
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setIsPop(true);
                              dispatch({ type: VIEW_ITEM_DETAIL, payload: item });
                            }}
                            style={{ color: "#0d6efd" }}
                          >
                            View Detail
                          </Button>
                        </td>
                        {currentTab === 'pending' && <td className="text-center">
                          <span onClick={() => setShowAction(showAction ? null : item?._id)} className="cursor-pointer"> . . .</span>
                        </td>}
                      </tr>
                      {showAction === item?._id && (
                        <div ref={actionRef} className="action-container">
                          <div onClick={() => { changeItemStatus(item?._id, true); setItem(item); }} className="cursor-pointer">Approve</div>
                          <div onClick={() => { changeItemStatus(item?._id, false); setItem(item); }} className="cursor-pointer">Unapproved</div>
                          <div> <Link
                            className="navbar-brand"
                            to={`/edit-list/${item._id}`}
                          >
                            Edit
                          </Link></div>
                        </div>
                      )}
                    </React.Fragment>
                  ))
                  : 
                  <EmptyData />
                  // <EmptyList name={currentTab} />
                  }
              </tbody>

            </table>
            <div className="mt-3">
              {itemTotal > 0 && (
                <CustomPagination
                  total={itemTotal}
                  onPageChange={(page, perPage) => { get_all_sell_Item(localStorage.getItem("token"), dispatch, page, perPage, currentTab); }}
                />
              )}
              {/* <button
                className="btn btn-sm me-2"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
              <span>Page {currentPage}</span>
              <button
                className="btn btn-sm ms-2"
                disabled={!itemData || itemData.length < perPage}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button> */}
            </div>
          </div>
          :
          currentTab === "actives" ?
            <RunningAuctions currentTab={currentTab} selectedCategory={selectedCategory} />
            :
            <PastAuction currentTab={currentTab} selectedCategory={selectedCategory} />
        }
      </div>
      <ViewDetail show={isPop} handleClose={() => { setIsPop(false); dispatch({ type: VIEW_ITEM_DETAIL, payload: null }); }} />
      <AprroveItem show={showapprove} handleClose={() => setShowapprove(false)} itemid={item} />
      <RejectItem show={showreject} handleClose={() => setShowreject(false)} itemid={item} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    itemdata: state?.user?.itemdata,
    itemTotal: state.user.itemTotal,
    pastTotal: state.user.pastTotal,
    runningTotal: state.user.runningTotal,
    categoriesList: state.user.categoriesList,
  };
};

export default connect(mapStateToProps)(ItemListing);
