import { initializeApp } from "firebase/app";
import { getFirestore }  from '@firebase/firestore';
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyB6u4QyTmrOBMfOUlWbjgKNEWMRf0agpvg",
//   authDomain: "gamingauction-f6ac1.firebaseapp.com",
//   projectId: "gamingauction-f6ac1",
//   storageBucket: "gamingauction-f6ac1.appspot.com",
//   messagingSenderId: "695735853893",
//   appId: "1:695735853893:web:1d74e4ea287933f754942c",
//   measurementId: "G-55JJV9YJQV"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDpObn_LTxXL9hi86X-S3Eyujl9tOsxSgg",
  authDomain: "gaming-6033d.firebaseapp.com",
  projectId: "gaming-6033d",
  storageBucket: "gaming-6033d.appspot.com",
  messagingSenderId: "48064140587",
  appId: "1:48064140587:web:d9faf6e53c2ec38dbe216e",
  measurementId: "G-SVYGDG8DBS"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();

export {db, auth, provider}