import { FormControl, FormHelperText, Input } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Form, FormLabel } from "react-bootstrap";
import ReactQuill from "react-quill";
import { upload_sell_item_photo } from "../../../reduxData/user/userAction";
import { create_faq_question, get_all_faq_questions, get_finalizing_content } from "../../../reduxData/cms/cmsAction";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const { REACT_APP_BASE_URL } = process.env;

const FinalizingContent = ({ finalize_Content }) => {
    const [formdata, setFormdata] = useState({
        heading: '',
        status: false,
    });
    const [errors, setErrors] = useState({
        heading: '',
    });
    const [contentData, setContentData] = useState([]);
    const [contentErrors, setContentErrors] = useState([]);
    const dispatch = useDispatch();

    const directions = [
        // { label: 'Top', value: 'top' },
        { label: 'Right', value: 'right' },
        // { label: 'Bottom', value: 'bottom' },
        { label: 'Left', value: 'left' }
    ];

    const handleAdd = () => {
        setContentData([...contentData, { subheading: '', subContent: [] }]);
        setContentErrors([...contentErrors, { subheading: '', subContent: [] }]);
    };

    const handleRemove = (index) => {
        const updateData = contentData.filter((_, i) => i !== index);
        setContentData(updateData);
        const removeError = contentErrors.filter((_, i) => i !== index);
        setContentErrors(removeError);
    };

    const handleChange = (value) => {
        setFormdata({ ...formdata, heading: value });
        setErrors({ ...errors, heading: !value ? "Heading is required" : null });
    };


    const handleStatus = (e) => {
        const { checked } = e.target;
        if (checked) {
            setFormdata({ ...formdata, status: true });
        } else {
            setFormdata({ ...formdata, status: false });
        }
    };

    const handleAddSub = (index, label) => {
        setContentData(contentData.map((item, i) => i === index ?
            {
                ...item, subheading: item.subheading,
                subContent: [
                    ...item.subContent,
                    label === 'content' ? { type: 'content', subCont: '' } : { type: 'imagePath', imagePath: '', direction: '' }
                ]
            }
            : item
        ));
        setContentErrors(contentErrors.map((item, i) => i === index ?
            {
                ...item, subheading: item.subheading,
                subContent: [
                    ...item.subContent,
                    label === 'content' ? { type: 'content', subCont: '' } : { type: 'imagePath', imagePath: '', direction: '' }
                ]
            }
            : item
        ))
    };

    const modules = {
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video'],
        ]
    };

    const handleSubHeading = (value, index) => {
        const updateContent = [...contentData];
        const updatedErrors = [...contentErrors];

        updateContent[index].subheading = value;
        setContentData(updateContent);

        updatedErrors[index].subheading = !value ? `Sub-Heading is required` : null;
        setContentErrors(updatedErrors);
    };

    const handleContentChange = async (type, value, contentIndex, subIndex) => {
        const updatedContent = [...contentData];
        const updateErrors = [...contentErrors];

        if (type === 'content') {
            if (updatedContent[contentIndex]?.subContent[subIndex]) {
                updatedContent[contentIndex].subContent[subIndex].subCont = value;
            }
            setContentData(updatedContent);

            if (updateErrors[contentIndex]?.subContent[subIndex]) {
                updateErrors[contentIndex].subContent[subIndex].subCont = !value || value === '<p><br></p>' || value === '<h3><br></h3>' ? `Content is required` : null;
            }
            setContentErrors(updateErrors);
        } else if (type === 'imagePath') {
            const uploaded = [value];
            const photoData = await upload_sell_item_photo(uploaded, localStorage.getItem("token"));
            if (updatedContent[contentIndex]?.subContent[subIndex]) {
                updatedContent[contentIndex].subContent[subIndex].imagePath = photoData?.data?.path[0] || '';
            }
            setContentData(updatedContent);

            if (updateErrors[contentIndex]?.subContent[subIndex]) {
                updateErrors[contentIndex].subContent[subIndex].imagePath = !value || value === '' ? `Image is required` : null;
            }
            setContentErrors(updateErrors);

        } else if (type === 'direction') {
            if (updatedContent[contentIndex]?.subContent[subIndex]) {
                updatedContent[contentIndex].subContent[subIndex].direction = value;
            }
            setContentData(updatedContent);

            if (updateErrors[contentIndex]?.subContent[subIndex]) {
                updateErrors[contentIndex].subContent[subIndex].direction = !value || value === '' ? `Image Direction is required` : null;
            }
            setContentErrors(updateErrors);
        }
    };

    const handleSubRemove = (contIndex, subIdx) => {
        const updatedContent = [...contentData];
        const updateErrors = [...contentErrors];

        if (contentData[contIndex]?.subContent) {
            const updateData = contentData[contIndex].subContent.filter((_, i) => i !== subIdx);
            updatedContent[contIndex] = {
                ...updatedContent[contIndex],
                subContent: updateData
            };

            setContentData(updatedContent);
        }

        if (contentErrors[contIndex]?.subContent) {
            const removeError = contentErrors[contIndex].subContent.filter((_, i) => i !== subIdx);
            updateErrors[contIndex] = {
                ...updateErrors[contIndex],
                subContent: removeError
            };

            setContentErrors(updateErrors);
        }
    };

    const handleRemoveImage = (contIndex, subIdx) => {
        const updatedContent = [...contentData];
        const updateErrors = [...contentErrors];
        if (updatedContent[contIndex]?.subContent[subIdx]) {
            updatedContent[contIndex].subContent[subIdx].imagePath = '';
        }
        setContentData(updatedContent);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formvalid = true;

        setErrors({ heading: !formdata.heading ? 'Heading is required' : null });

        const newErrors = [...contentErrors];

        if (formdata.heading && contentData && contentData?.length === 0) {
            formvalid = false;
            toast.error("Add the Necessary Content for Selling a Collectible");
        }

        contentData.forEach((item, index) => {

            if (item?.subheading === '') {
                newErrors[index].subheading = 'Sub-Heading is required';
                formvalid = false;
            }

            if (item?.subContent && item?.subContent?.length > 0) {
                item.subContent.map((itm, idx) => {
                    if (itm?.type === 'content') {
                        if (itm?.subCont === '' || itm?.subCont === '<p><br></p>' || itm?.subCont === '<h3><br></h3>') {
                            newErrors[index].subContent[idx].subCont = 'Content is required';
                            formvalid = false;
                        }
                    }

                    if (itm?.type === 'imagePath') {

                        if (itm?.direction === '') {
                            newErrors[index].subContent[idx].direction = 'Image Direction is required';
                            formvalid = false;
                        }

                        if (itm?.imagePath === '') {
                            newErrors[index].subContent[idx].imagePath = 'Image is required';
                            formvalid = false;
                        }
                    }
                });
            }
        });
        setContentErrors([...newErrors]);

        if (formdata?.heading && formvalid) {

            const content = {
                name: 'finalize_collectible',
                type: 'finalize_collectible',
                status: formdata.status,
                content: {
                    heading: formdata.heading,
                    contentData,
                }
            };
            await create_faq_question(localStorage.getItem("token"), content, finalize_Content?.[0]?._id);
        }
    };

    useEffect(() => {
        const handleContent = async () => {
            const getData = await get_finalizing_content(localStorage.getItem("token"), "finalize_collectible", dispatch);
            if (getData?.length > 0 && getData[0]?.content) {
                setFormdata({
                    heading: getData[0]?.content?.heading,
                    status: getData[0]?.status
                });
                setContentData(getData[0]?.content?.contentData);
                const newErr = getData[0]?.content?.contentData?.map(item => {
                    return {
                        ...item,
                        subheading: '',
                        subContent: item?.subContent?.map(itm => {
                            if (itm.type === "content") {
                                return { ...itm, subCont: '' };
                            } else if (itm.type === "imagePath") {
                                return { ...itm, imagePath: '', direction: '' };
                            } else {
                                return itm;
                            }
                        })
                    };
                }) || [];
                setContentErrors(newErr);
            }
        };
        handleContent();
    }, []);

    return (
        <div className="container mt-3">
            <div className="d-flex justify-content-between">
                <h4 className="mb-2 fw-600 pt-2">Finalizing a collectible</h4>
                <div className="d-flex">
                    <Button type="button" className="btn common-button" onClick={handleAdd}>Add Content +</Button>
                    {/* <Button type="button" className="btn common-button">Add Image +</Button> */}
                </div>
            </div>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <FormControl className="mt-2 mb-2">
                    <FormLabel className="fw-600">Heading</FormLabel>
                    <Input
                        type="text"
                        name="answer"
                        value={formdata?.heading}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                    {errors?.heading && <div className="error_msg">{errors?.heading}</div>}
                </FormControl>

                {contentData?.map((item, index) => (
                    <div key={index}>
                        <div className="container">
                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <h4 className="mb-2 fw-600 pt-2">Content {index + 1}</h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <div className="d-flex gap-3 mb-2">
                                                <FormLabel className="fw-600 pt-2">Sub-Heading</FormLabel>
                                                <Input
                                                    type="text"
                                                    name="answer"
                                                    value={item?.subheading}
                                                    onChange={(e) => handleSubHeading(e.target.value, index)}
                                                />
                                                <Button type="button" className="btn common-button" onClick={() => handleAddSub(index, 'content')}>Add Sub Content +</Button>
                                                <Button type="button" className="btn common-button" onClick={() => handleAddSub(index, 'image')}>Add Image +</Button>
                                                <Button className="question-button" variant="danger" onClick={() => handleRemove(index)}>Remove Content -</Button>
                                            </div>

                                            {contentErrors[index] && contentErrors[index]?.subheading && (
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {contentErrors[index]?.subheading}
                                                </FormHelperText>
                                            )}

                                            {item?.subContent && item?.subContent?.map((i, idx) => (
                                                i?.type === 'content' ?
                                                    <div key={idx} className="position-relative">
                                                        <FormControl className="mt-1 mb-2">
                                                            <FormLabel className="fw-600">Sub Content</FormLabel>
                                                            <ReactQuill
                                                                name="answer"
                                                                value={i?.subCont}
                                                                modules={modules}
                                                                onChange={(value) => handleContentChange('content', value, index, idx)}
                                                            />
                                                            {contentErrors[index]?.subContent[idx] && contentErrors[index]?.subContent[idx]?.subCont && (
                                                                <FormHelperText style={{ color: 'red' }}>
                                                                    {contentErrors[index]?.subContent[idx]?.subCont}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                        <div className="position-absolute upload-file-close pt-1 ms-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm rounded-circle"
                                                                onClick={() => handleSubRemove(index, idx)}
                                                            >
                                                                <i class="fa-solid fa-xmark color-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="upload-file position-relative" key={idx}>
                                                        <FormControl className="mt-2 mb-2">
                                                            <FormLabel className="fw-600">Image</FormLabel>
                                                            <div className="d-flex col-lg-6 gap-3">
                                                                <sapn>Image Direction :</sapn>
                                                                <select name="diretion" type="select" className="" value={i?.direction} style={{ appearance: 'auto' }}
                                                                    onChange={(e) => handleContentChange('direction', e.target.value, index, idx)}
                                                                >
                                                                    <option value='' disabled>Select</option>
                                                                    {directions?.map((item, index) => (
                                                                        <option key={index} value={item?.value}>{item?.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            {contentErrors[index]?.subContent[idx] && contentErrors[index]?.subContent[idx]?.direction && (
                                                                <FormHelperText style={{ color: 'red' }}>
                                                                    {contentErrors[index]?.subContent[idx]?.direction}
                                                                </FormHelperText>
                                                            )}

                                                            {i?.imagePath ?
                                                                <div className="position-relative">
                                                                    <img
                                                                        src={`${REACT_APP_BASE_URL}${i?.imagePath}`}
                                                                        style={{
                                                                            height: '400px',
                                                                            width: '100%'
                                                                        }}
                                                                    />
                                                                    <div className="position-absolute upload-file-close mt-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm rounded-circle"
                                                                            onClick={() => handleRemoveImage(index, idx)}
                                                                        >
                                                                            <i class="fa-solid fa-xmark color-white"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <label className="form-control text-center" style={{ height: '200px', paddingTop: '3rem', marginTop: '1rem' }}>
                                                                    +{" "}
                                                                    <span className="d-block">
                                                                        (Click to Upload Images){" "}
                                                                        <span className="d-block">
                                                                            (Jpg, png, Webp, Avif, format support) &nbsp;
                                                                        </span>{" "}
                                                                    </span>
                                                                    <input
                                                                        className="form_control d-none"
                                                                        id="photos"
                                                                        type="file"
                                                                        accept="image/png, image/jpeg, image/jpg, image/gif"
                                                                        onChange={(e) => handleContentChange('imagePath', e.target.files[0], index, idx)}
                                                                    />
                                                                </label>
                                                            }
                                                            <div className="position-absolute upload-file-close mt-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm rounded-circle"
                                                                    onClick={() => handleSubRemove(index, idx)}
                                                                >
                                                                    <i class="fa-solid fa-xmark color-white"></i>
                                                                </button>
                                                            </div>
                                                            {contentErrors[index]?.subContent[idx] && contentErrors[index]?.subContent[idx]?.imagePath && (
                                                                <FormHelperText style={{ color: 'red' }}>
                                                                    {contentErrors[index]?.subContent[idx]?.imagePath}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </div>
                                            ))}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                ))}
                <FormControl className="mt-1 mb-2">
                    <FormLabel className="fw-600">Status</FormLabel>
                    <div class="form-check form-switch switch-large">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={formdata.status} onChange={handleStatus} />
                    </div>
                </FormControl>
                <Button type="submit" className="btn common-button">Save</Button>
            </Form>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        faqItems: state.cms.faqItems,
        finalize_Content: state.cms.finalizeCont,
    }
};
export default connect(mapStateToProps)(FinalizingContent);
