import React, { useEffect, useState } from "react";
import { get_particular_user, update_user } from "../reduxData/user/userAction";
import { useNavigate, useParams } from "react-router-dom";

const AddEditUserManagement = () => {

  const [formData, setFormData] = useState({
    name:"",
    dateOfJoining: "",
    email: "",
    phone_number: "",
    isDeactive:"Deactivate",
    user_name: ""
  });

  const [errors, setErrors] = useState({
    name:"",
  })

  const { id } = useParams();
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await get_particular_user(id,localStorage.getItem('token'));
        console.log(data);
        setFormData({
          name: data.name || "",
          dateOfJoining: data.createdAt || "",
          email: data.email || "",
          phone_number: data.phoneNumber || "",
          user_name:data?.user_name || "",
          isDeactive: data.isDeactive?'Deactivate':'Active' 
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [id]); // Include id in the dependency array

  const handleChange = (e) => {

    const { name, value } = e.target;
    if(name =='name'){
      if(!value){
        setErrors({...errors, [name]:"Name is Required"})
      }else {
        setErrors({...errors, [name]:""})
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(errors.name){
      return;
    }
    const data = {
      dateOfJoining: formData?.dateOfJoining,
      email: formData?.email,
      isDeactive: formData?.isDeactive,
      name: formData?.name,
      phoneNumber: formData?.phone_number,
      user_name: formData?.user_name
    }
    let isedit = await update_user(id,data,navigate,localStorage.getItem('token'));
    if(isedit){
      setFormData({
        name: "",
        dateOfJoining: "",
        email: "",
        phone_number: "",
        user_name: ""
      });
    }
  };

  const changeDateFormat = (data) => {
    const dateObject = new Date(data);
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObject.getDate()).slice(-2);
    const formattedDate = `${year}-${day}-${month}`;
    return formattedDate;
  };

  return (
<div className="container">
  <h3 className="mb-0 fw-600">{id ? 'Edit' : 'Add'} User Management</h3>
  <form onSubmit={handleSubmit} className="row mt-4">
    <div className="mb-3 col-md-6">
      <label htmlFor="name" className="form-label fw-600">
        Name of User
      </label>
      <input
        type="text"
        className="form-control"
        placeholder="Enter name"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <div className="error_msg">{errors?.name && errors.name}</div>
    </div>
    <div className="mb-3 col-md-6">
      <label className="form-label fw-600">
        User Name
      </label>
      <input
        type="text"
        className="form-control"
        name="dateOfJoining"
        disabled
        value={formData.user_name}
      />
    </div>
    <div className="mb-3 col-md-6">
      <label htmlFor="dateOfJoining" className="form-label fw-600">
        Date of Joining
      </label>
      <input
        type="text"
        className="form-control"
        id="dateOfJoining"
        name="dateOfJoining"
        disabled
        value={changeDateFormat(formData.dateOfJoining)}
        onChange={handleChange}
      />
    </div>
    <div className="mb-3 col-md-6">
      <label htmlFor="email" className="form-label fw-600">
        Email
      </label>
      <input
        type="email"
        className="form-control"
        id="email"
        name="email"
        value={formData.email}
        disabled
        onChange={handleChange}
      />
    </div>
    <div className="mb-3 col-md-6">
      <label htmlFor="phone_number" className="form-label fw-600">
        Phone Number
      </label>
      <input
        type="number"
        className="form-control"
        id="phone_number"
        name="phone_number"
        placeholder="Enter phone no."
        value={formData.phone_number}
        onChange={handleChange}
      />
    </div>
    <div className="mb-3 col-md-6">
      <label htmlFor="isDeactive" className="form-label fw-600">
        Status
      </label>
      <select
        className="form-select"
        id="isDeactive"
        name="isDeactive"
        value={formData.isDeactive}
        onChange={handleChange}
      >
        <option value="Active">Active</option>
        <option value="Deactivate">Inactive</option>
      </select>
    </div>
    <div className="mb-3 col-md-12">
      <button type="submit" className="btn common-button">
        Submit
      </button>
    </div>
  </form>
</div>

  
  );
};

export default AddEditUserManagement;
