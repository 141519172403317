import authReducer from './auth/authReducer';
import { combineReducers } from 'redux';
import userReducer from './user/userReducer';
import cmsReducer from './cms/cmsReducer';


const rootReducer = combineReducers({
  auth:authReducer,
  user:userReducer,
  cms:cmsReducer,
});

export default rootReducer;
