import React from "react";
import EmptyLogo from '../images/EmptyData.jpg';

const EmptyData = () => {
    return (
        <div className="d-flex justify-content-center align-items-center">
        <img src={EmptyLogo} alt="no img found"/>
      </div>
    )
};

export default EmptyData;