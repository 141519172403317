import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { getCountries, getCountryCallingCode, isValidPhoneNumber } from "react-phone-number-input";
import en from 'react-phone-number-input/locale/en';
import 'react-phone-number-input/style.css'

const PhoneNo = ({ sellItem, setSellItem, handlePhone, country, setCountry }) => {
    // const [count, setCount] = useState(country);
    const CountrySelect = ({ value, labels, ...rest }) => (
        <Dropdown>
            <Dropdown.Toggle variant="default">
                +{getCountryCallingCode(country)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {getCountries().map((country) => (<Dropdown.Item key={country} onClick={() => { setCountry(country); setSellItem(); }}>
                    {labels[country]}(+{getCountryCallingCode(country)})
                </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )

    return (
        <div className={'phone-input password position-relative d-flex align-items-center'}>
            <CountrySelect
                value={country}
                labels={en}
            />
            <input defaultCountry={country} type="number" name="phone_number" className="form-control" placeholder="Phone Number" value={sellItem} onChange={(e) => handlePhone(e.target.value, 'phone_number')} />
        </div>
    )
};

export default PhoneNo;
