import React, { useEffect, useRef, useState } from "react";
import { GetCountries, GetState, } from "react-country-state-city";
import { Country, State } from "country-state-city";
import { connect, useDispatch } from 'react-redux';
// import { get_all_sell_Item, sell_video_game, upload_edit_item_video } from "../../reduxdata/rootAction";
import { sell_video_game, upload_edit_item_video, upload_sell_item_logal_docs, upload_sell_item_photo } from "../reduxData/user/userAction";
import { get_single_sell_Item } from "../reduxData/user/userAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import PhoneNo from "../Shared/PhoneNo";
import { getCountryCallingCode, isValidPhoneNumber } from "react-phone-number-input";
import { usePlacesWidget } from "react-google-autocomplete";
import AutoInput from "../Shared/AutoInput";
import { saveAs } from 'file-saver';
import moment from "moment-timezone";
import StorePhoneno from "../Shared/StorePhoneno";
const PdfImage1 = require('../images/pdficon.png');
const DocxImage2 = require('../images/DocxIcon.png');

const { REACT_APP_BASE_URL } = process.env;

const EditListItem = ({ user }) => {
    const [formdata, setFormData] = useState({
        fullname: '',
        phone_number: '',
        owner_email: '',
        additional_fees: null,
        store_name: '',
        store_website: '',
        store_email: '',
        store_phone_number: '',
        range: '',
        legal_doc: null,
        itemname: '',
        iteminfo: '',
        versiontype: '',
        condition: '',
        knowsflaws: '',
        itemlocation: '',
        higlights: null,
        modificationinfo: null,
        servicehistory: null,
        ownercountry: '',
        ownerstate: '',
        sellernotes: null,
        reserveprice: '',
        mediaimage: [],
        mediavideo: [],
        bidstart: '',
        bidend: ''
    });
    const [errors, setErrors] = useState({
        fullname: '',
        phone_number: '',
        owner_email: '',
        additional_fees: "",
        store_name: "",
        store_website: "",
        store_email: "",
        store_phone_number: "",
        range: "",
        itemname: '',
        iteminfo: '',
        versiontype: '',
        condition: '',
        // knowsflaws: '',
        itemlocation: '',
        zipcode: '',
        // higlights: '',
        // modificationinfo: '',
        // servicehistory: '',
        ownercountry: '',
        ownerstate: '',
        // sellernotes: '',
        reserveprice: '',
        mediaimage: '',
        // mediavideo: '',
        bidstart: '',
        bidend: ''
    });
    const [edititem, setEditItem] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const [country, setCountry] = useState('US');
    const [storeCountry, setStoreCountry] = useState('US');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const navigate = useNavigate();
    // ************
    const params = useParams()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await get_single_sell_Item(params?.id);
                // console.log("Edit Dataaa......", data);
                let edit = data?.data?.data;
                // setSellItem({
                //   game_info: edit?.game_info,
                //   seller_type: edit?.owner?.store_name ? "store" : "privateOwner",
                //   full_name: edit?.owner?.full_name,
                //   phone_number: edit?.owner?.phone_number,
                //   additional_fees: edit?.owner?.additional_fees,
                //   store_name: edit?.owner?.store_name,
                //   store_website: edit?.owner?.store_website,
                //   range: edit?.owner?.range,
                //   item_name: edit?.item_name,
                //   category: edit?.category,
                //   version_type: edit?.version_type,
                //   condition: edit?.condition,
                //   known_flaws: edit?.known_flaws,
                //   location: edit?.location,
                //   highlights_info: edit?.highlights_info,
                //   modifications_info: edit?.modifications_info,
                //   service_history: edit?.service_history,
                //   owner_country: edit?.owner_country,
                //   owner_state: edit?.owner_state,
                //   seller_note: edit?.seller_note,
                //   reserver_price: edit?.reserver_price,
                //   notes: edit?.notes,
                //   start_date: edit?.start_date,
                //   end_date: edit?.end_date,
                //   type: "new",
                // });
                setEditItem(data?.data?.data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchData();
    }, []);
    // **********************?//
    // console.log(edititem);

    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const imageref = useRef();
    const videoref = useRef();
    const documentref = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        const countryData = Country.getAllCountries();
        setCountriesList(countryData);
    }, []);
    // console.log("countries", countriesList);
    // console.log("states", stateList);

    const versiontypes = [
        { id: 1, name: 'Countertop', value: 'Countertop' },
        { id: 2, name: 'Full size', value: 'Full_size' },
        { id: 3, name: 'Non Applicable', value: 'Non_Applicable' }
    ];
    const conditions = [
        // { id: 1, name: 'Original', value: 'Original' },
        // { id: 2, name: 'Restored', value: 'Restored' }
        { id: 1, name: 'Like New', value: 'Like New' },
        { id: 2, name: 'Good', value: 'Good' },
        { id: 3, name: 'Fair', value: 'Fair' },
        { id: 4, name: 'Poor', value: 'Poor' }
    ];
    const [isReserve, setIsReserve] = useState('');
    const [isFeatured, setIsFeatured] = useState(false);
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [imgFile, setImgFile] = useState([]);
    const [videoFile, setVideoFile] = useState([]);
    const [docFile, setDocFile] = useState([]);
    const [removeImages, setRemoveImages] = useState([]);

    const handleImage = () => {
        imageref.current.click();
    };
    const handleVideo = () => {
        videoref.current.click();
    };
    const handleDoc = () => {
        documentref.current.click();
    };

    const validateStartDate = (dateString) => {
        const providedDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifference = providedDate.getTime() - currentDate.getTime();
        const hoursDifference = timeDifference / (1000 * 3600);
        return hoursDifference > 1;
    };
    const validStartDate = (start, end) => {
        const startDate = moment.tz(start, userTimeZone);
        const endDate = moment.tz(end, userTimeZone);
        return startDate < endDate;
    };

    const validateEndDate = (startdate, enddate) => {
        const startdte = new Date(startdate);
        const enddte = new Date(enddate);
        const currentdte = new Date();
        return enddte < startdte ? false : true;
    };

    const formatDateTimeLocal = (dateString) => {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Format as YYYY-MM-DDTHH:MM
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const handleReserve = (event) => {
        const { value, checked } = event.target;
        setIsSubmit(false);
        if (checked) {
            setIsReserve(true);
        } else {
            setIsReserve(false);
            setErrors((prevErrors) => ({ ...prevErrors, reserver_price: null }));
            // setFormData({ ...formdata,reserveprice: ''});
        }
    };


    const [location, setLocation] = useState('');
    const [zipCode, setZipCode] = useState('');
    const handleLocation = (value, label) => {
        setIsSubmit(false);
        switch (label) {
            case 'location':
                setErrors({ ...errors, itemlocation: value === "" ? "Location is required" : "" });
                setLocation(value);
                break;
            case 'zipcode':
                setErrors({ ...errors, zipcode: value === "" ? "Zip Code is required" : "" });
                setZipCode(value);
                break;
            default:
                break;
        }
    };

    const handleprice = (price) => {
        const formatddprice = parseInt(price);
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            currency: 'USD',
        });
        return `$${formatter.format(formatddprice)}`;
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        const exptest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsSubmit(false);

        switch (name) {
            case 'fullname':
                setErrors({ ...errors, fullname: value === "" ? "Full name is required" : "" });
                setFormData({ ...formdata, fullname: value });
                break;
            case 'owner_email':
                setErrors({ ...errors, owner_email: value === "" ? "Email is required" : !exptest.test(value) ? "Email is Invalid" : "" });
                setFormData(prev => ({ ...prev, owner_email: value }));
                break;
            case 'store_email':
                setErrors({ ...errors, store_email: value === "" ? "Email is required" : !exptest.test(value) ? "Email is Invalid" : "" });
                setFormData(prev => ({ ...prev, store_email: value }));
                break;
            case 'additional_fees':
                setErrors({ ...errors, additional_fees: value === "" ? "Store Fees is required" : "" });
                setFormData(prev => ({ ...prev, additional_fees: value }));
                break;
            case 'store_name':
                setErrors({ ...errors, store_name: value === "" ? "Store Name is required" : "" });
                setFormData(prev => ({ ...prev, store_name: value }));
                break;
            case 'store_website':
                const invalidPatterns = /http|www|co|in/i;
                setErrors({ ...errors, store_website: value === "" ? "Store Website is required" : !invalidPatterns.test(value) ? 'Website url is not valid' : "" });
                setFormData(prev => ({ ...prev, store_website: value }));
                break;
            case 'range':
                setErrors({ ...errors, range: value === "" ? "Price Range is required" : "" });
                setFormData(prev => ({ ...prev, range: value }));
                break;
            case 'itemname':
                setErrors({ ...errors, itemname: value === "" ? "Item name is required" : "" });
                setFormData({ ...formdata, itemname: value });
                break;
            case 'iteminfo':
                setErrors({ ...errors, iteminfo: value === "" ? "Game info is required" : "" });
                setFormData({ ...formdata, iteminfo: value });
                break;
            case 'versiontype':
                setErrors({ ...errors, versiontype: value === "" ? "Version type is required" : "" });
                setFormData({ ...formdata, versiontype: value });
                break;
            case 'condition':
                setErrors({ ...errors, condition: value === "" ? "Condition is required" : "" });
                setFormData({ ...formdata, condition: value });
                break;
            case 'knowsflaws':
                setErrors({ ...errors, knowsflaws: value === "" ? "Flaws is required" : "" });
                setFormData({ ...formdata, knowsflaws: value });
                break;
            case 'itemlocation':
                setErrors({ ...errors, itemlocation: value === "" ? "Location is required" : "" });
                setFormData({ ...formdata, itemlocation: value });
                break;
            case 'higlights':
                setErrors({ ...errors, higlights: value === "" ? "Highlights is required" : value.length > 50 ? "Highlights must be between 1 and 50 characters" : "" });
                setFormData({ ...formdata, higlights: value });
                break;
            case 'modificationinfo':
                setErrors({ ...errors, modificationinfo: value === "" ? "Modifications is required" : "" });
                setFormData({ ...formdata, modificationinfo: value });
                break;
            case 'servicehistory':
                setErrors({ ...errors, servicehistory: value === "" ? "Service history is required" : "" });
                setFormData({ ...formdata, servicehistory: value });
                break;
            case 'sellernotes':
                setErrors({ ...errors, sellernotes: value === "" ? "Seller note is required" : "" });
                setFormData({ ...formdata, sellernotes: value });
                break;
            case 'reserveprice':
                const rawValue = value.replace(/[$,]/g, '');
                setErrors({ ...errors, reserveprice: value === "" ? "Reserve price is required" : "" });
                setFormData({ ...formdata, reserveprice: rawValue ? handleprice(rawValue) : "$0" });
                break;

            default:
                setFormData({ ...formdata, [name]: value, });
                setErrors({ ...errors, [name]: '' });
                break;
        }
    };

    const handlePhone = (value, fieldName) => {
        setIsSubmit(false);
        switch (fieldName) {
            case 'phone_number':
                let countrycode = getCountryCallingCode(country);
                let checkvalue = `+${countrycode}` + value;
                setErrors({ ...errors, phone_number: value === "" ? "Phone number is required" : !isValidPhoneNumber(checkvalue) ? "Invalid phone number" : "" });
                setFormData({ ...formdata, phone_number: value });
                break;
            case 'store_phone_number':
                let storecountrycode = getCountryCallingCode(storeCountry);
                let storecheckvalue = `+${storecountrycode}` + value;
                setErrors({ ...errors, store_phone_number: value === "" ? "Phone no. is required" : !isValidPhoneNumber(storecheckvalue) ? "Invalid phone number" : "" });
                setFormData({ ...formdata, store_phone_number: value });
                break;
            default:
                break;
        }
    };

    const getMinDate = () => {
        const currentDate = moment().tz(userTimeZone);
        currentDate.add(24, 'hours');
        return currentDate.format('YYYY-MM-DDTHH:mm');
        // const currentDate = new Date();
        // currentDate.setHours(currentDate.getHours() + 72);
        // return currentDate.toISOString().slice(0, 16);
    };

    const getMinEndDate = (startDate) => {
        if (!startDate) return '';
        const startDateTime = moment(startDate).tz(userTimeZone);
        startDateTime.add(1, 'day');
        return startDateTime.format('YYYY-MM-DDTHH:mm');

        // if (!startDate) return '';
        // const startDateTime = new Date(startDate);
        // startDateTime.setDate(startDateTime.getDate() + 1);
        // return startDateTime.toISOString().slice(0, 16);
    };

    const handleFeatured = (event) => {
        const { checked } = event.target;
        if (checked) {
            setIsFeatured(true);
        } else {
            setIsFeatured(false);
        }
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setIsSubmit(false);

        switch (name) {
            case 'bidstart':
                setErrors({
                    ...errors, bidstart: !validateStartDate(value) ? "Start Date should add prior 1 hour"
                        // : !validStartDate(value, formdata.bidend) ? "Start Date cannot be after the End date" 
                        : null
                });
                setFormData({ ...formdata, [name]: value, });
                break;
            case 'bidend':
                setErrors({ ...errors, bidend: !value ? "End Date is required" : null });
                setErrors({ ...errors, bidstart: validateEndDate(formdata?.bidstart, value) ? null : "Start Date cannot be after the End date" })
                setFormData({ ...formdata, [name]: value, });
                break;

            default:
                setFormData({ ...formdata, [name]: value, });
                setErrors({ ...errors, [name]: '' });
                break;
        }
    };

    const handleFiles = async (e) => {
        const { name, value, files } = e.target;
        setIsSubmit(false);

        switch (name) {
            case 'mediaimage':
                const fileupload = [files[0]];
                if (fileupload) {
                    const newimages = [];
                    for (let i = 0; i < files.length; i++) {
                        const uploadedfile = files[i];
                        const imageUpload = [uploadedfile];
                        const photoData = await upload_sell_item_photo(imageUpload, localStorage.getItem("token"));
                        if (photoData !== undefined) {
                            const photosFiles = photoData?.data?.path.map((item) => item);
                            newimages.push({
                                preview: URL.createObjectURL(uploadedfile),
                                imgdata: uploadedfile
                            });
                            if (photosFiles) {
                                imgFile.push(...photosFiles);
                            }

                        }
                    }
                    setImages(prevImages => [...prevImages, ...newimages]);
                    setErrors({ ...errors, mediaimage: imgFile?.length === 0 ? "Photo is required" : "" });
                };
                break;
            case 'mediavideo':
                const videoupload = [files[0]];
                if (videoupload) {
                    const newvideos = [];
                    for (let i = 0; i < files.length; i++) {
                        const uploadedvideo = files[i];
                        const videoupload = [uploadedvideo];
                        const videdata = await upload_edit_item_video(videoupload, user?.token, dispatch);
                        if (videdata !== undefined) {
                            const videofiles = videdata.data.path.map((item) => item);
                            if (videofiles) {
                                videoFile.push(...videofiles);
                                newvideos.push(...videofiles);
                            }
                        }
                    }
                    setVideos(prevVideos => [...prevVideos, ...newvideos]);
                };
                break;
            case 'store_legal_docs':
                const newDocs = Array.from(files);
                const docsData = await upload_sell_item_logal_docs(newDocs, localStorage.getItem("token"));
                setDocFile(prevDocs => [...prevDocs, ...docsData?.data?.path]);
                break;
            default:
                setFormData({ ...formdata, [name]: value, });
                setErrors({ ...errors, [name]: '' });
                break;
        };
    };

    const handleSelectCountry = (e) => {
        const countryname = e.target.value;
        const getcountry = countriesList.find(d => d.name === countryname);
        setIsSubmit(false);

        if (getcountry) {
            const getState = State.getStatesOfCountry(getcountry?.isoCode);
            setStateList(getState);
        }
        setFormData({ ...formdata, ownercountry: countryname, });
    };

    const handleRemoveDoc = (item) => {
        const totaldocs = docFile.filter((itm) => itm !== item);
        setDocFile([...totaldocs]);
        setIsSubmit(false);

        // setIsEdit(false);
        // setIsEdit(false);
    };

    const handleDeleteImage = (index, item) => {
        if (edititem) {
            setImgFile((prevImages) => {
                const newImages = [...prevImages];
                newImages.splice(index, 1);
                return newImages;
            });
            setImages((prevpath) => {
                const newPath = [...prevpath];
                newPath.splice(index, 1);
                return newPath;
            });
            if (removeImages.includes(item)) {
                const filterremoves = removeImages.filter((itm) => itm !== item);
                setRemoveImages([...filterremoves]);
            }
        }
        setErrors({ ...errors, mediaimage: imgFile?.length === 0 ? "Photo is required" : "" });
        setIsSubmit(false);
    };

    const handleDeleteVideo = (item) => {
        let Videos = videos.filter((itm) => itm !== item);
        setVideos([...Videos]);

        let VideoFile = videoFile.filter((itm) => itm !== item);
        setVideoFile([...VideoFile]);
        setIsSubmit(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { fullname, phone_number, store_phone_number, additional_fees, store_name, store_email, store_website, range, itemname, iteminfo, versiontype, condition, itemlocation, higlights,
            modificationinfo, servicehistory, ownercountry, ownerstate, sellernotes, reserveprice, bidstart, bidend } = formdata;
        let countrycode = getCountryCallingCode(country);
        let checkvalue = `+${countrycode}${phone_number}`;
        let storecountrycode = getCountryCallingCode(storeCountry ? storeCountry : 'US');
        let storevalue = `+${storecountrycode}` + store_phone_number;
        const exptest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const invalidPatterns = /http|www|co|in/i;

        setErrors({
            fullname: !fullname ? "Full name is required" : "",
            phone_number: !phone_number ? "Phone number. is required" : !isValidPhoneNumber(checkvalue) ? "Invalid phone number" : "",
            itemname: !itemname ? "Item name is required" : "",
            iteminfo: !iteminfo ? "Item info is required" : "",
            store_phone_number: store_phone_number == '' ? "Store Phone number is required" : !isValidPhoneNumber(storevalue) ? "Invalid Phone Number" : null,
            versiontype: !versiontype ? "Version type is required" : "",
            condition: !condition ? "Condition is required" : "",
            itemlocation: !location ? "Location is required" : "",
            zipcode: zipCode === '' ? "Zip Code is required" : "",
            higlights: !higlights ? "Highlights is required" : higlights?.length > 50 ? "Highlights must be between 1 and 50 characters" : "",
            modificationinfo: !modificationinfo ? "Modification info is required" : "",
            servicehistory: !servicehistory ? "Service history is required" : "",
            ownercountry: !ownercountry ? "Owner country is required" : "",
            ownerstate: !ownerstate ? "Owner state is required" : "",
            sellernotes: !sellernotes ? "Seller notes is required" : "",
            reserveprice: !reserveprice && isReserve ? "Reserve price is required" : "",
            mediaimage: imgFile?.length === 0 ? "Photo is required" : "",
            bidstart: !bidstart || bidstart === '' ? "Start date is required" : "",
            bidend: !bidend || bidend === '' ? "End date is required" : !validateEndDate(bidstart, bidend) ? "End date cannot be before the start date." : ""
        });

        if (edititem.owner?.range !== null) {
            setErrors({
                ...errors,
                additional_fees: additional_fees == '' ? 'Store Fees is required' : null,
                owner_email: formdata.owner_email == '' ? 'Owner Email is required' : null,
                store_name: store_name == '' ? 'Store Name is required' : null,
                store_website: store_website == '' ? 'Store Website is required' : !invalidPatterns.test(store_website) ? 'Website url is not valid' : null,
                store_email: formdata.store_email == '' ? 'Email is required' : null,
                store_phone_number: formdata.store_phone_number == '' ? "Store Phone number is required" : !isValidPhoneNumber(storevalue) ? "Invalid Phone Number" : null,
                range: range == '' ? 'Price Range is required' : null,
            });
        }

        setErrors({
            ...errors,
            mediaimage: imgFile?.length === 0 ? "Photo is required" : imgFile?.length > 0 && imgFile?.length < 9 ? "Minimum 9 Images are required" : "",
            bidstart: formdata.bidstart === "" ? 'Start Date is required' : null,
            bidend: formdata.bidend === "" ? "End Date is required" : null,
        });

        if (fullname && isValidPhoneNumber(checkvalue) && itemname && iteminfo && versiontype && condition && location && zipCode &&
            higlights?.length < 50 && modificationinfo && sellernotes && servicehistory && ownercountry && ownerstate && bidstart && bidend
            && validateEndDate(bidstart, bidend) && imgFile?.length > 8) {
            const editData = {
                full_name: formdata?.fullname,
                phone_number: formdata?.phone_number,
                item_name: formdata?.itemname,
                game_info: formdata?.iteminfo,
                version_type: formdata?.versiontype,
                condition: formdata?.condition,
                location: location,
                zipcode: zipCode,
                highlights_info: formdata?.higlights ? formdata?.higlights : null,
                modifications_info: formdata?.modificationinfo ? formdata?.modificationinfo : null,
                service_history: formdata?.servicehistory ? formdata?.servicehistory : null,
                owner_country: formdata?.ownercountry,
                owner_state: formdata?.ownerstate,
                seller_note: formdata?.sellernotes ? formdata?.sellernotes : null,
                photos: [...imgFile],
                video: videoFile?.length > 0 ? [...videoFile] : null,
                start_date: formdata?.bidstart,
                end_date: formdata?.bidend,
                country_code: country,
                is_featured: isFeatured,
                item_id: edititem?._id,
                type: 'edit'
            };
            if (isReserve) {
                editData.reserver_price = parseInt(formdata?.reserveprice?.replace(/[$,]/g, ''));
            }
            if (formdata?.knowsflaws) {
                editData.known_flaws = formdata?.knowsflaws;
            }

            if (isReserve === true && editData?.reserver_price == 0) {
                setErrors({ ...errors, reserveprice: "Reseve Price should be greater than 0" });
                return;
            }

            if (edititem?.owner?.store_legal_docs?.length > 0) {
                editData.additional_fees = formdata.additional_fees;
                editData.owner_email = formdata.owner_email;
                editData.store_name = formdata.store_name;
                editData.store_website = formdata.store_website;
                editData.store_email = formdata.store_email;
                editData.store_phone_number = formdata.store_phone_number;
                editData.range = formdata.range;
                editData.legal_doc = docFile;
                editData.store_country_code = storeCountry;
            }
            // console.log("hititititiittitittttt", errors);
            if ((edititem?.owner?.store_legal_docs && edititem?.owner?.store_legal_docs?.length > 0) && ((formdata.additional_fees == '' || formdata.store_name == '' || formdata.store_website == '' || !invalidPatterns.test(formdata.store_website) || formdata.range == '' || docFile?.length === 0) ||
                (formdata?.owner_email == '' || formdata?.store_email == '') || !exptest.test(formdata?.owner_email) ||
                !exptest.test(formdata?.store_email) || !isValidPhoneNumber(storevalue))
            ) {
                return;
            }

            // console.log("date to be change...", editData);
            setIsSubmit(true);
            await sell_video_game(editData, localStorage.getItem('token'));
            setIsSubmit(false);
            navigate('/item-listing');


            // if (removeImages.length > 0) {
            //     editData.removephotos = [...removeImages];
            // }
            // console.log("date to be change...", editData);

            // if (isReserve === true && editData?.reserver_price) {
            //     if (editData?.reserver_price === 0) {
            //         setErrors({ ...errors, reserveprice: "Reseve Price should be greater than 0" });
            //         return;
            //     }
            //     await sell_video_game(editData, localStorage.getItem('token'));
            //     navigate('/item-listing');
            // } else if (isReserve === false) {
            //     console.log("hiititittitiititittititi", editData);
            //     await sell_video_game(editData, localStorage.getItem('token'));
            //     navigate('/item-listing');
            // }
        }
    };

    useEffect(() => {
        if (countriesList?.length > 0) {
            const getcountry = countriesList.find(d => d.name === edititem?.owner_country);
            if (getcountry) {
                const getState = State.getStatesOfCountry(getcountry?.isoCode);
                setStateList(getState);
            }
        }
    }, [countriesList, edititem]);

    useEffect(() => {
        if (edititem) {
            // console.log(edititem);
            // const startdate = new Date(edititem?.start_date);
            // const enddate = new Date(edititem?.end_date);
            setImages(edititem?.photos?.map((path) => {
                return { preview: REACT_APP_BASE_URL + path, imgdata: path, }
            }));
            setImgFile(edititem?.photos?.map((item) => item));
            setRemoveImages(edititem?.photos?.map((item) => item));
            if (!edititem.video.includes('null')) {
                setVideos(edititem?.video?.map((item) => item));
                setVideoFile(edititem?.video?.map((item) => item));
            } else { setVideos([]); setVideoFile([]); }

            setFormData(prev => {
                return ({
                    ...prev,
                    fullname: edititem?.owner?.full_name !== "null" ? edititem?.owner?.full_name : "",
                    phone_number: edititem?.owner?.phone_number !== "null" ? edititem?.owner?.phone_number : "",
                    owner_email: edititem?.owner?.owner_email ? edititem?.owner?.owner_email : null,
                    additional_fees: edititem?.owner?.additional_fees ? edititem?.owner?.additional_fees : null,
                    store_name: edititem?.owner?.store_name ? edititem?.owner?.store_name : null,
                    store_website: edititem?.owner?.store_website ? edititem?.owner?.store_website : null,
                    store_email: edititem?.store_email ? edititem?.store_email : null,
                    store_phone_number: edititem?.store_phone_number ? edititem?.store_phone_number : null,
                    range: edititem?.owner?.range ? edititem?.owner?.range : null,
                    legal_doc: edititem?.owner?.store_legal_docs && edititem?.owner?.store_legal_docs?.length > 0 ? edititem?.owner?.store_legal_docs : null,
                    itemname: edititem?.item_name !== "null" ? edititem?.item_name : "",
                    iteminfo: edititem?.game_info !== "null" ? edititem?.game_info : "",
                    versiontype: edititem?.version_type !== "null" ? edititem?.version_type : "",
                    condition: edititem?.condition !== "null" ? edititem?.condition : "",
                    knowsflaws: edititem?.known_flaws !== "null" ? edititem?.known_flaws : '',
                    itemlocation: edititem?.location !== "null" ? edititem?.itemlocation : "",
                    higlights: edititem?.highlights_info !== "null" ? edititem?.highlights_info : '',
                    modificationinfo: edititem?.modifications_info !== "null" ? edititem?.modifications_info : '',
                    servicehistory: edititem?.service_history !== "null" ? edititem?.service_history : '',
                    ownercountry: edititem?.owner_country !== "null" ? edititem?.owner_country : "",
                    ownerstate: edititem?.owner_state !== "null" ? edititem?.owner_state : "",
                    sellernotes: edititem?.seller_note !== "null" ? edititem?.seller_note : '',
                    reserveprice: edititem?.reserver_price !== "null" ? handleprice(edititem?.reserver_price) : '',
                    bidstart: edititem?.start_date !== null ? formatDateTimeLocal(edititem?.start_date) : '',
                    bidend: edititem?.end_date !== null ? formatDateTimeLocal(edititem?.end_date) : '',

                    // bidstart: edititem?.start_date ? startdate.toISOString().split('T')[0] : '',
                    // bidend: edititem?.end_date ? enddate.toISOString().split('T')[0] : ''
                })
            });
            setCountry(edititem?.country_code ? edititem?.country_code : 'US');
            setStoreCountry(edititem?.store_country_code);
            setDocFile(edititem?.owner?.store_legal_docs);
            setLocation(edititem?.location !== "null" ? edititem?.location : null);
            setZipCode(edititem?.zipcode !== "null" ? edititem?.zipcode : null);
            if (parseInt(edititem?.reserver_price) > 0) {
                setIsReserve(true)
            } else {
                setIsReserve(false);
            }

            if (edititem?.store_country_code) {
                setStoreCountry(edititem?.store_country_code);
            }

            if (edititem?.is_featured) {
                setIsFeatured(edititem?.is_featured);
            }
        }
    }, [edititem]);

    const handleDownload = async (url) => {
        // const filepath = url.includes('+') ? url.replace(/\+/g, '%2B') : url;
        const fileContent = `${REACT_APP_BASE_URL}download?file=${url}`;
        const fileName = url?.substring(url?.lastIndexOf("/") + 1);
        const getMimeType = (ext) => {
            const mimeTypes = {
                txt: "text/plain",
                pdf: "application/pdf",
                zip: "application/zip",
                jpg: "image/jpeg",
                jpeg: "image/jpeg",
                png: "image/png",
                gif: "image/gif",
                mp4: "video/mp4",
                mov: "video/quicktime"
            };
            return mimeTypes[ext] || "application/octet-stream";
        };

        const response = await fetch(fileContent);
        const blobFile = await response.blob();
        const fileExtension = fileName?.split(".").pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);
        const blobwithtype = new Blob([blobFile], { type: mimeType });
        saveAs(blobwithtype, fileName);
    };

    // console.log(edititem);

    return (
        <div>
            <div className="row align-items-center">
                <div className="col-md-6 col-lg-6">
                    <h1 className="fw-bold text-black h3 mb-0">Edit Item</h1>
                </div>
                <div className="col-md-6 col-lg-6 text-end">
                    <Link href="itemList" className="btn common-button back-btn" to="/item-listing">
                        Back
                    </Link>
                </div>
            </div>
            <div className="profile-deatils bg-white mt-4">
                <div className="detail-heading px-4 py-3"><div className="text-dark fw-600 h5 mb-0">Item Details</div> </div>
                <div className="p-4">
                    <div className="row">
                        <form onSubmit={handleSubmit} noValidate>
                            <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Owner Details
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Full Name</label>
                                                    <div className="password position-relative">
                                                        <input type="text" name="fullname" className="form-control" placeholder="Full Name" value={formdata?.fullname} onChange={handleChange} />
                                                    </div>
                                                    {errors?.fullname && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.fullname}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Phone Number</label>
                                                    <div className="phone-input password position-relative">
                                                        <PhoneNo
                                                            sellItem={formdata?.phone_number}
                                                            setSellItem={() => setFormData({ ...formdata, phone_number: '' })}
                                                            handlePhone={handlePhone}
                                                            country={country}
                                                            setCountry={(value) => setCountry(value)}
                                                        />
                                                    </div>
                                                    {errors?.phone_number && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.phone_number}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        {edititem?.owner?.owner_email && <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Owner email</label>
                                                    <div className="password position-relative">
                                                        <input type="email" name="owner_email" className="form-control" placeholder="Enter owner email" defaultValue={formdata?.owner_email} onChange={handleChange} onInput={handleChange} />
                                                    </div>
                                                    {errors?.owner_email && <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">{errors?.owner_email}</p>}
                                                </div>
                                            </div>
                                        </div>}
                                        {edititem?.owner?.additional_fees && <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Additional Fees</label>
                                                    <div className="password position-relative">
                                                        <input type="text" name="additional_fees" className="form-control" placeholder="Enter additional fee" defaultValue={formdata?.additional_fees} onChange={handleChange} onInput={handleChange} />
                                                    </div>
                                                    {errors?.additional_fees && <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">{errors?.additional_fees}</p>}
                                                </div>
                                            </div>
                                        </div>}
                                        {edititem?.owner?.store_name && <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Store Name</label>
                                                    <div className="password position-relative">
                                                        <input type="text" name="store_name" className="form-control" placeholder="Enter store name" defaultValue={formdata?.store_name} onChange={handleChange} onInput={handleChange} />
                                                    </div>
                                                    {errors?.store_name && <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">{errors?.store_name}</p>}
                                                </div>
                                            </div>
                                        </div>}
                                        {edititem?.owner?.store_website && <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Store Website</label>
                                                    <div className="password position-relative">
                                                        <input type="text" name="store_website" className="form-control" placeholder="Enter website" defaultValue={formdata?.store_website} onChange={handleChange} onInput={handleChange} />
                                                    </div>
                                                    {errors?.store_website && <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">{errors?.store_website}</p>}
                                                </div>
                                            </div>
                                        </div>}
                                        {edititem?.store_email && <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Store email</label>
                                                    <div className="password position-relative">
                                                        <input type="email" name="store_email" className="form-control" placeholder="Enter store email" defaultValue={formdata?.store_email} onChange={handleChange} onInput={handleChange} />
                                                    </div>
                                                    {errors?.store_email && <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">{errors?.store_email}</p>}
                                                </div>
                                            </div>
                                        </div>}
                                        {edititem?.store_phone_number && <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Store Phone Number</label>
                                                    <div className="phone-input position-relative">
                                                        <StorePhoneno
                                                            sellItem={formdata?.store_phone_number}
                                                            setSellItem={() => setFormData({ ...formdata, store_phone_number: '' })}
                                                            handlePhone={handlePhone}
                                                            country={storeCountry}
                                                            setCountry={(value) => setStoreCountry(value)}
                                                        />
                                                    </div>
                                                    {errors?.store_phone_number && <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">{errors?.store_phone_number}</p>}
                                                </div>
                                            </div>
                                        </div>}
                                        {edititem?.owner?.range && <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Range</label>
                                                    <div className="password position-relative">
                                                        <input type="text" name="range" className="form-control" placeholder="Enter range" defaultValue={formdata?.range} onChange={handleChange} onInput={handleChange} />
                                                    </div>
                                                    {errors?.range && <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">{errors?.range}</p>}
                                                </div>
                                            </div>
                                        </div>}
                                        {edititem?.owner?.store_legal_docs?.length > 0 && <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Store Documents</label>
                                                    <input className="d-none" type="file" name="store_legal_docs" accept=".pdf, .doc, .docx" ref={documentref} onChange={handleFiles} multiple />
                                                    <button type="button" className="p-0 bg-transparent border-0 ms-3" onClick={handleDoc}> <i class="fa-solid fa-upload"></i></button>
                                                    <div className="row mt-3">
                                                        {docFile?.map((item, index) => (
                                                            <div key={index} className="col-md-4">
                                                                <div className=" position-relative pb-3 d-flex h-100">
                                                                    {item?.split(".").pop().toLowerCase() === 'pdf' ?
                                                                        <img src={PdfImage1} alt="Img1" className="cursor-pointer object-fit-cover" height={'70%'} width={'70%'} onClick={() => handleDownload(item)} />
                                                                        :
                                                                        <img src={DocxImage2} alt="Img2" className="cursor-pointer object-fit-cover" height={'70%'} width={'70%'} onClick={() => handleDownload(item)} />
                                                                    }
                                                                    {/* <Link className="cursor-pointer" style={{ color: "#0d6efd" }}>
                                                                        {item} <br />
                                                                    </Link> */}
                                                                    {/* <img src={item.preview} className="rounded img-fluid object-fit-cover" width={'100%'} /> */}
                                                                    <div className="position-absolute upload-file-close">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm rounded-circle"
                                                                            onClick={() => handleRemoveDoc(item)}>
                                                                            <i class="fa-solid fa-xmark color-white"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {docFile?.length === 0 &&
                                                        <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">Store Documents is required</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>}
                                    </Accordion.Body>

                                </Accordion.Item>

                                <Accordion.Item eventKey="1" className="mt-4">
                                    <Accordion.Header>
                                        Item Details
                                    </Accordion.Header>
                                    <Accordion.Body >

                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Item Name</label>
                                                    <div className="password position-relative">
                                                        <input type="text" name="itemname" className="form-control" placeholder="Item Name" value={formdata?.itemname} onChange={handleChange} />
                                                    </div>
                                                    {errors?.itemname && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.itemname}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Item Information</label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="iteminfo"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your item information"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.iteminfo}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </div>
                                                    {errors?.iteminfo && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.iteminfo}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-1 col-lg-1">
                                                <label className="text-dark fw-600 mb-1">
                                                    Featured
                                                </label>
                                                <label className="switch m-0">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={isFeatured} onChange={handleFeatured} />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-4">
                                                    <label className="text-dark fw-600 mb-2">Version Type</label>
                                                    <div className="password position-relative">
                                                        <select name="versiontype" type="select" className="form-control" value={formdata?.versiontype} onChange={handleChange}>
                                                            <option value='' disabled>Select</option>
                                                            {versiontypes?.map((item, index) => (
                                                                <option key={index} value={item?.label}>{item?.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {errors?.versiontype && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.versiontype}</p>}
                                                </div>
                                                <div className="col-md-4 col-lg-4">
                                                    <label className="text-dark fw-600 mb-2">Condition</label>
                                                    <div className="password position-relative">
                                                        <select name="condition" type="select" className="form-control" style={{ appearance: 'menulist' }} value={formdata?.condition} onChange={handleChange}>
                                                            <option value='' disabled>Select</option>
                                                            {conditions?.map((item, index) => (
                                                                <option key={index} value={item?.label}>{item?.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {errors?.condition && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.condition}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Known Flaws </label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="knowsflaws"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your flaws"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.knowsflaws}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </div>
                                                    {errors?.knowsflaws && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.knowsflaws}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Item Location</label>
                                                    <AutoInput
                                                        address={location}
                                                        handleChange={(value, label) => handleLocation(value, label)}
                                                    />
                                                    {/* <div className="password position-relative">
                                                        <input type="text" name="itemlocation" className="form-control" placeholder="location" value={formdata?.itemlocation} onChange={handleChange} />
                                                    </div> */}
                                                    {errors?.itemlocation && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.itemlocation}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Zip Code</label>
                                                    <div className="password position-relative">
                                                        <input type="text" name="zipcode" className="form-control" placeholder="zipcode" value={zipCode}
                                                            onChange={(e) => handleLocation(e.target.value, 'zipcode')}
                                                            onInput={(e) => handleLocation(e.target.value, 'zipcode')}
                                                        />
                                                    </div>
                                                    {errors?.zipcode && <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">{errors?.zipcode}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Highlights </label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="higlights"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your highlight info"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.higlights}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </div>
                                                    {errors?.higlights && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.higlights}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Modifications Info </label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="modificationinfo"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your modifications info"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.modificationinfo}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </div>
                                                    {errors?.modificationinfo && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.modificationinfo}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Service History </label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="servicehistory"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your Service History"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.servicehistory}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </div>
                                                    {errors?.servicehistory && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.servicehistory}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="text-dark fw-600 mb-2">Ownership Info</label>
                                            <div className="row">
                                                <div className="col-md-4 col-lg-4">
                                                    <div className="password position-relative">
                                                        <select name="versionType" type="select" className="form-control" style={{ appearance: 'menulist' }}
                                                            onChange={handleSelectCountry}
                                                            value={formdata?.ownercountry}
                                                        >
                                                            <option value="">Select</option>
                                                            {countriesList.map((item, index) => (
                                                                <option key={index} value={item?.name}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-4">
                                                    <div className="password position-relative">
                                                        <select name="ownerstate" type="select" className="form-control" style={{ appearance: 'menulist' }}
                                                            onChange={handleChange}
                                                            value={formdata?.ownerstate}>
                                                            {stateList?.length > 0 && stateList.map((item, index) => (
                                                                <option key={index} value={item?.name}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Seller Notes </label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="sellernotes"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your Seller Notes"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.sellernotes}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </div>
                                                    {errors?.sellernotes && <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.sellernotes}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-3 col-lg-3">
                                                    <label className="text-dark fw-600 mb-2">Reserve Price</label>
                                                    <div className="password position-relative d-flex gap-2 align-items-center">
                                                        <div className="check_list">
                                                            <input type="checkbox" id="reserved_price" style={{ width: '20px', height: '20px' }} onChange={handleReserve} checked={isReserve} />
                                                            <label for="reserved_price"></label>

                                                        </div>
                                                        <span style={{ fontSize: '24px', paddingTop: '10px' }}><i class="bi bi-currency-dollar fw-bold"></i></span>
                                                        <input
                                                            type="text"
                                                            name="reserveprice"
                                                            className="form-control"
                                                            placeholder=""
                                                            value={formdata?.reserveprice}
                                                            onChange={handleChange}
                                                            disabled={!isReserve}
                                                        />
                                                    </div>
                                                    {(isReserve === true && errors?.reserveprice) &&
                                                        <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.reserveprice}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2" className="mt-4">
                                    <Accordion.Header>
                                        Media Data
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="mb-4">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-0">Image</label>
                                                    <input className="d-none" type="file" name="mediaimage" accept="image/*" ref={imageref} onChange={handleFiles} multiple />
                                                    <button type="button" className="p-0 bg-transparent border-0 ms-3" onClick={handleImage}> <i class="fa-solid fa-upload"></i></button>
                                                    <div className="row mt-3">
                                                        {images?.map((item, index) => (
                                                            <div key={index} className="col-md-3">
                                                                <div className=" position-relative pb-3 d-flex h-100" >
                                                                    <img src={item.preview} className="img-fluid object-fit-cover rounded" width={'100%'} />
                                                                    <div className="position-absolute upload-file-close">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm rounded-circle"
                                                                            onClick={() => handleDeleteImage(index, item.imgdata)}>
                                                                            <i class="fa-solid fa-xmark color-white"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {errors.mediaimage &&
                                                        <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">{errors.mediaimage}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-0">Video (Optional)</label>
                                                    <input className="d-none" type="file" name="mediavideo" accept="video/*" ref={videoref} onChange={handleFiles} multiple />
                                                    <button type="button" className="p-0 bg-transparent border-0 ms-3" onClick={handleVideo}> <i class="fa-solid fa-upload"></i></button>
                                                    <div className="row mt-3">
                                                        {videos?.map((item, index) => (
                                                            <div key={index} className="col-md-3">
                                                                <div className=" position-relative pb-3">
                                                                    <video src={`${REACT_APP_BASE_URL}${item}`} style={{ height: '100%', width: '100%' }} controls />
                                                                    <div className="position-absolute upload-file-close">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm rounded-circle"
                                                                            onClick={() => handleDeleteVideo(item)}>
                                                                            <i class="fa-solid fa-xmark color-white"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {/* {videoFile?.length === 0 &&
                                                        <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">Video is required</p>
                                                    } */}
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <div className="mt-4">
                                    <h4 className="text-black mb-3 fw-600">Bid Timing</h4>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-4 col-lg-4">
                                                <div className="password position-relative">
                                                    <label className="text-dark fw-600 mb-2">Start Date</label>
                                                    <input type="datetime-local" name="bidstart" className="form-control" value={formdata?.bidstart} min={getMinDate()} onChange={handleDateChange} />
                                                </div>
                                                {errors?.bidstart &&
                                                    <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.bidstart}</p>
                                                }
                                            </div>
                                            <div className="col-md-4 col-lg-4">
                                                <div className="password position-relative">
                                                    <label className="text-dark fw-600 mb-2">End Date</label>
                                                    <input type="datetime-local" name="bidend" className="form-control" value={formdata?.bidend} min={getMinEndDate(formdata?.bidstart)} onChange={handleDateChange} />
                                                </div>
                                                {formdata?.bidend === '' && errors?.bidend &&
                                                    <p className="d-flex flex-start text-danger error_msg mb-1 mb-md-0">{errors?.bidend}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <div className="d-flex gap-3 pt-4">
                                        <button className="btn common-button" type="submit" disabled={isSubmit}>Save Changes</button>
                                        <button className="btn common-button back-btn" onClick={() => navigate('/item-listing')}>Cancel</button>
                                    </div>
                                </div>
                            </Accordion>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EditListItem;
