export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_TOTAL_USERS = "GET_TOTAL_USERS";
export const VIEW_ITEM_DETAIL = "VIEW_ITEM_DETAIL";
export const GET_ITEM_LISTING = "GET_ITEM_LISTING";
export const GET_ALL_PAST_AUCTIONS = "GET_ALL_PAST_AUCTIONS";
export const GET_ALL_RUNNING_AUCTIONS = "GET_ALL_RUNNING_AUCTIONS";
export const GET_ITEM_TOTAL = "GET_ITEM_TOTAL";
export const GET_PAST_TOTAL = "GET_PAST_TOTAL";
export const GET_RUNNING_TOTAL = "GET_RUNNING_TOTAL";
export const GET_ALL_BID_AUCTIONS = "GET_ALL_BID_AUCTIONS";
export const GET_BID_TOTAL = "GET_BID_TOTAL";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_TOTAL_CATEGORIES = "GET_TOTAL_CATEGORIES";
export const GET_BADGE_LISTS = "GET_BADGE_LISTS";