import React, { useEffect, useRef, useState } from "react";
import { FormControl, FormLabel, Input } from "@mui/joy";
import { Accordion, Button, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { create_faq_question, get_all_faq_questions } from "../../reduxData/cms/cmsAction";
import { upload_sell_item_photo } from "../../reduxData/user/userAction";

const { REACT_APP_BASE_URL } = process.env;

const HomeContent = ({ faqItems }) => {

    const [formData, setFormData] = useState({
        // content: '',
        contentwo: '',
        imagePath: '',
        status: false,
    });
    const [errors, setErrors] = useState({
        // content: '',
        imagePath: '',
        contenttwo: ''
    });
    const dispatch = useDispatch();
    const imageRef = useRef();
    const handleClick = () => {
        imageRef.current.click();
    };

    const modules = {
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video'],
        ]
    };

    const handleChange = (value, label) => {

        switch (label) {
            // case 'content':
            //     setErrors({ ...errors, content: !value || value === '' ? "Content is required" : null });
            //     setFormData({ ...formData, content: value });
            //     break;
            case 'contentwo':
                setErrors({ ...errors, contenttwo: !value || value === '' ? "Content is required" : null });
                setFormData({ ...formData, contentwo: value });
                break;
            default:
                setFormData({ ...formData, [label]: value });
                break;
        }
    };

    const handleImageChange = async (e) => {
        const imageUpload = [e.target.files[0]];
        const photoData = await upload_sell_item_photo(imageUpload, localStorage.getItem("token"));
        const photosFiles = photoData?.data?.path[0];
        setFormData({ ...formData, imagePath: photosFiles });
        setErrors({ ...errors, imagePath: null });
    };

    const handleStatus = (e) => {
        const { checked } = e.target;
        if (checked) {
            setFormData({ ...formData, status: true });
        } else {
            setFormData({ ...formData, status: false });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { content, contentwo, imagePath } = formData;
        setErrors({
            ...errors,
            // content: !content || content === '' ? "Content 1 is required" : null,
            contenttwo: !contentwo || contentwo === '' ? "Content is required" : null,
            imagePath: !imagePath || imagePath === '' ? "Image is required" : null,
        });
        if (contentwo && imagePath) {
            const quest_data = {
                name: "home_content",
                type: "home_content",
                status: formData.status,
                content: {
                    // content1: formData?.content,
                    content: formData?.contentwo,
                    imagePath: formData?.imagePath,
                }
            };
            await create_faq_question(localStorage.getItem("token"), quest_data, faqItems[0]?._id);
            await get_all_faq_questions(localStorage.getItem("token"), "home_content", dispatch);
        }
    };

    useEffect(() => {
        const handleQuest = async () => {
            await get_all_faq_questions(localStorage.getItem("token"), "home_content", dispatch);
        };
        handleQuest();
    }, []);

    useEffect(() => {
        if (faqItems?.length > 0) {
            setFormData({
                // content: faqItems[0]?.content?.content1,
                contentwo: faqItems[0]?.content?.content,
                imagePath: faqItems[0]?.content?.imagePath,
                status: faqItems[0]?.status,
            });
        }
    }, [faqItems]);

    return (
        <div className="container">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <h4 className="mb-0 fw-bold">Home Content</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <Form onSubmit={handleSubmit}>
                                {/* <FormControl className="mt-2 mb-2">
                                    <FormLabel className="fw-600">Content 1</FormLabel>
                                    <Input
                                        type="text"
                                        name="answer"
                                        value={formData.content}
                                        onChange={(e) => handleChange(e.target.value, 'content')}
                                    />
                                    {errors?.content && <div className="error_msg">{errors?.content}</div>}
                                </FormControl> */}
                                <FormControl className="mt-2 mb-2">
                                    <FormLabel className="fw-600">Content</FormLabel>
                                    <Input
                                        type="text"
                                        name="answer"
                                        value={formData.contentwo}
                                        onChange={(e) => handleChange(e.target.value, 'contentwo')}
                                    />
                                    {errors?.contenttwo && <div className="error_msg">{errors?.contenttwo}</div>}
                                </FormControl>
                                <FormControl className="mt-2 mb-2">
                                    <FormLabel className="fw-600">Image</FormLabel>
                                    {formData?.imagePath &&
                                        <div className="position-relative pb-3 d-flex h-100" >
                                            <img
                                                src={`${REACT_APP_BASE_URL}${formData?.imagePath}`}
                                                style={{
                                                    height: '400px',
                                                    width: '100%'
                                                }}
                                            />
                                            <div className="position-absolute upload-file-close">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm rounded-circle"
                                                    onClick={() => { setFormData({ ...formData, imagePath: '' }) }}>
                                                    <i class="fa-solid fa-xmark color-white"></i>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    <input type="file" className="d-none" accept="image/png, image/jpg, image/jpeg, image/gif" ref={imageRef} onChange={handleImageChange} />
                                    <Button className="btn upload-button" onClick={handleClick}>Upload</Button>
                                    {errors?.imagePath && <div className="error_msg">{errors?.imagePath}</div>}
                                </FormControl>
                                <FormControl className="mt-1 mb-2">
                                    <FormLabel className="fw-600">Status</FormLabel>
                                    <div class="form-check form-switch switch-large">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={formData.status} onChange={handleStatus} />
                                    </div>
                                </FormControl>
                                <Button type="submit" className="btn common-button">Save</Button>
                            </Form>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        faqItems: state.cms.faqItems,
    }
};

export default connect(mapStateToProps)(HomeContent);
