import { Pagination, TablePagination } from "@mui/material";
import React, { useState } from "react";

const EmptyActions = () => {};

const CustomPagination = ({ total, onPageChange }) => {
    const [page, setPage] = useState(1);
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    //table
    const handleNavClick = (event, newPage) => {
        setPage(newPage);
        onPageChange(newPage + 1, rowsPerPage);
    };
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
        onPageChange(1, parseInt(event.target.value, 10));
    };

    //button
    const pageCount = Math.ceil(total / rowsPerPage);
    const handlePageChange = (event, value) => {
        setPage(value);
        onPageChange(value, rowsPerPage);
    };

    return (
        <div className="col-lg-12 d-flex align-items-center justify-content-center custom-pagination">
            <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                // shape="rounded"
                // variant="outlined"
                color="primary"
                sx={{
                    "& .Mui-selected": {
                        backgroundColor: "#FAB406 !important",
                        color: "#fff",
                    },
                }}
            />
            <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                onPageChange={handleNavClick}
                count={total}
                page={tablePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                ActionsComponent={EmptyActions}
                labelDisplayedRows={() => null}
            />
        </div>
    )
};

export default CustomPagination;
