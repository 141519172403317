import React, { useEffect, useState } from "react";
import AddQuestion from "../../Modals/AddQuestion";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { create_faq_question, get_all_faq_questions, update_items_sequence } from "../../reduxData/cms/cmsAction";
import EmptyData from "../../components/EmptyData";
import { GET_EDIT_DATA } from "../../reduxData/cms/cmsTypes";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteContent from "../../Modals/DeleteContent";

const FAQ = ({ user, faqItems }) => {
    const [isShow, setIsShow] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [editdata, setEditdata] = useState(null);
    const dispatch = useDispatch();
    var currentPage = JSON.parse(localStorage.getItem("currentQuest"));
    const [currentQuest, setCurrentQuest] = useState("general");

    useEffect(() => {
        if (currentPage) {
            setCurrentQuest(currentPage);
        } else {
            setCurrentQuest("general");
        }
    }, []);

    useEffect(() => {
        const handleQuest = async () => {
            await get_all_faq_questions(localStorage.getItem("token"), currentQuest, dispatch);
        };
        handleQuest();
    }, [currentQuest]);

    const handleDragEnd = async (result) => {
        if (!result.destination) return;
        let items = Array.from(faqItems);
        const [reordereditem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reordereditem);
        const updated_sequence = {
            sequence: items?.map((item) => item?._id)
        };
        let updated = await update_items_sequence(localStorage.getItem("token"), updated_sequence, currentQuest, dispatch);
        if (updated) await get_all_faq_questions(localStorage.getItem("token"), currentQuest, dispatch);
    };

    const handleToogle = async (status, item) => {

        const quest_data = {
            name: "FAQ",
            type: item.type,
            status: item.status === true ? false : true,
            content: {
                question: item?.content?.question,
                answer: item?.content?.answer,
            }
        };

        let question_created = await create_faq_question(localStorage.getItem("token"), quest_data, item?._id);
        if (question_created) {
            await get_all_faq_questions(localStorage.getItem("token"), currentQuest, dispatch);
        }
    };

    return (
        <div className="mt-1">
            <div className="container ">
                <div className="d-flex justify-content-between">
                    <h3 className="fw-500">FAQ's</h3>
                    <div className="col-lg-4 col-md-6 pt-2">
                        <div className="text-md-end">
                            <ul className="list-inline mb-0 sorting-list">
                                <li className="list-inline-item mb-2 mb-md-0">
                                    <Link
                                        className={`text-decoration-none border rounded-pill px-3 py-2 ${currentQuest === 'general' ? 'active' : ''}`}
                                        onClick={() => { setCurrentQuest("general"); localStorage.setItem("currentQuest", JSON.stringify("general")); }}
                                    >
                                        General
                                    </Link>
                                </li>
                                <li className="list-inline-item mb-2 mb-md-0">
                                    <Link
                                        className={`text-decoration-none border rounded-pill px-3 py-2 ${currentQuest === 'seller' ? 'active' : ''}`}
                                        onClick={() => { setCurrentQuest("seller"); localStorage.setItem("currentQuest", JSON.stringify("seller")); }}
                                    >
                                        Seller
                                    </Link>
                                </li>
                                <li className="list-inline-item mb-2 mb-md-0">
                                    <Link
                                        className={`text-decoration-none border rounded-pill px-3 py-2 ${currentQuest === 'buyer' ? 'active' : ''}`}
                                        onClick={() => { setCurrentQuest("buyer"); localStorage.setItem("currentQuest", JSON.stringify("buyer")); }}
                                    >
                                        Buyer
                                    </Link>
                                </li>
                            </ul>
                        </div>{" "}
                    </div>
                    <button className="btn common-button" onClick={() => { setIsShow(true); }}>Add New Question</button>
                </div>

                <div className="col-lg-12 mt-2">
                    <h5 className="fw-500">
                        {currentQuest === "seller" ? 'Seller' : currentQuest === "buyer" ? 'Buyer' : 'General'} Questions
                    </h5>
                    <div className="table-responsive mt-4">
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="faqlist">
                                {(provided) => (
                                    <table className="table table-hover user-management-table" {...provided.droppableProps} ref={provided.innerRef}>
                                        <thead className="border-gray">
                                            <th>#</th>
                                            <th>Question</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </thead>
                                        <tbody>
                                            {faqItems?.length > 0 ?
                                                faqItems?.map((item, index) => (
                                                    <Draggable key={item?._id} draggableId={item?._id} index={index}>
                                                        {(provided) => (
                                                            <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.content?.question}</td>
                                                                <td>
                                                                    <div class="form-check form-switch switch-large">
                                                                        <input
                                                                            class="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="flexSwitchCheckDefault"
                                                                            checked={item?.status}
                                                                            onChange={(e) => handleToogle(e.target.value, item)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="d-flex pt-4">
                                                                    <div onClick={() => { dispatch({ type: GET_EDIT_DATA, payload: item }); setIsShow(true); }}>
                                                                        <i className="cursor-pointer mx-2 far fa-edit edit" />
                                                                    </div>
                                                                    <div>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16"
                                                                            height="16"
                                                                            fill="currentColor"
                                                                            viewBox="0 0 16 16"
                                                                            className="cursor-pointer delete-icon bi bi-trash3-fill"
                                                                            onClick={() => { setEditdata(item); setIsDelete(true); }}
                                                                        >
                                                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                                                        </svg>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))
                                                :
                                                <EmptyData />
                                            }
                                        </tbody>
                                    </table>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>
            <AddQuestion show={isShow} handleClose={() => { dispatch({ type: GET_EDIT_DATA, payload: null }); setIsShow(false); }} />
            <DeleteContent
                show={isDelete}
                handleClose={() => { setEditdata(null); setIsDelete(false); }}
                data={editdata}
                heading={currentQuest === "seller" ? 'Seller FAQ' : currentQuest === "buyer" ? 'Buyer FAQ' : 'General FAQ'}
                otherLine={"question"}
                isFaq={true}
                currentQuest={currentQuest}
            />
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        faqItems: state.cms.faqItems,
    }
};

export default connect(mapStateToProps)(FAQ);
