import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Table } from "@mui/joy";
import { Link } from "react-router-dom";
import { get_users } from "../reduxData/user/userAction";
import { Box, Input } from "@mui/joy";
import CustomPagination from "./CustomPagination";
import EmptyData from "./EmptyData";

const UserManagement = ({ allUsers, totalUsers }) => {
  const [userManagement, setUserManagement] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await get_users(
          dispatch,
          localStorage.getItem("token"),
          1,
          10,
          name,
          email
        );
        setUserManagement(data?.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [currentPage, perPage, name, email]);

  const changeDOJFormat = (data) => {
    const joinDate = new Date(data);
    const formattedJoinDate = joinDate.toLocaleDateString();
    return formattedJoinDate
  }

  return (
    <div className="container">
      <Box className="d-flex mb-4 justify-content-between">
        <h3 className="mb-0 fw-600">Users ({totalUsers})</h3>

        <div className="d-flex justify-content-between">
          <div className="mx-3">
            <Input
              id="nameInput"
              type="text"
              placeholder="Name of user"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <Input
              id="emailInput"
              type="text"
              placeholder="Email of user"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </Box>
      <div className="table-responsive">
        <table className="table user-management-table table-hover">
          <thead className="border-gray">
            <th scope="col">Name of User</th>
            <th scope="col">Username</th>
            <th scope="col" className="text-center">Date of Joining</th>
            <th scope="col">Email</th>
            {/* <th scope="col" className="text-center">Phone Number</th> */}
            <th scope="col" className="text-center">Status</th>
            <th scope="col" className="text-center">Action</th>
          </thead>
          <tbody>
            {totalUsers > 0 ?
              allUsers.map((data, index) => (
                <tr key={index}>
                  <td>{data.name ? data.name : '-'}</td>
                  <td>{data?.user_name ? data?.user_name : '-'}</td>
                  <td className="text-center">{changeDOJFormat(data.createdAt)}</td>
                  <td>{data.email}</td>
                  {/* <td className="text-center">{data.phoneNumber ? data.phoneNumber : '-'}</td> */}
                  <td className="text-center">
                    {data?.isDeactive ? (

                      "Inactive"

                    ) : (

                      "Active"
                    )}
                  </td>
                  <td className="text-center">
                    {/* <button className="btn btn-sm btn-main m-2"> */}
                    <Link
                      className="navbar-brand "
                      to={`/editUser/${data._id}`}
                    >
                      <i className="far fa-edit edit"></i>
                    </Link>
                    {/* </button> */}

                  </td>
                </tr>
              )): <EmptyData />}
          </tbody>
        </table>
      </div>

      <div className="mt-3">
        {totalUsers > 0 && (
          <CustomPagination
            total={totalUsers}
            onPageChange={(page, perPage) => { get_users(dispatch, localStorage.getItem("token"), page, perPage); }}
          />
        )}
        {/* <button
          className="btn btn-sm me-2"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          className="btn btn-sm ms-2"
          disabled={!userManagement || userManagement.length < perPage}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allUsers: state.user.allUsers,
    totalUsers: state.user.totalUsers
  };
};

export default connect(mapStateToProps)(UserManagement);
