import { USER_UPDATE } from "./authTypes";


const initialState = {
  user: JSON.parse(localStorage.getItem('auth')),
  };
  
  export const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case USER_UPDATE:
        return {
          ...state,
          user: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  