import axios from "axios";
import { toast } from "react-toastify";
import { check_token_expired_logout } from "../user/userAction";
import { GET_ALL_FAQS, GET_BUYING_CONTENT, GET_CONTACT_US_CONTENT, GET_FINALIZE_CONTENT, GET_INSERTBID_CONTENT, GET_NEWSLETTER_CONTENT, GET_SELL_ITEM_CONTENT, GET_SELLING_CONTENT } from "./cmsTypes";


const { REACT_APP_BASE_URL } = process.env;


export const create_faq_question = async (token, form_data, id) => {
    try {
        const url = id ? `${REACT_APP_BASE_URL}admin/update-content/${id}` : `${REACT_APP_BASE_URL}admin/add-content`;
        const HEADERS = {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            }
        };
        const res = id ? await axios.put(url, form_data, HEADERS) : await axios.post(url, form_data, HEADERS);
        if (res.data && res.data.status) {
            toast.success(res.data.message, { toastId: "addeditem1", autoClose: 2000 });
            return true;
        }
    } catch (error) {
        console.log(error);
        // check_token_expired_logout(error);
    }
};

export const get_all_faq_questions = async (token, question_type, dispatch) => {
    try {
        const url = `${REACT_APP_BASE_URL}admin/list-content/${question_type}`;
        const HEADERS = {
            headers: {
                'x-access-token': token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            if (question_type === 'InsertBid_content') {
                dispatch({ type: GET_INSERTBID_CONTENT, payload: res.data.data });
            } else if (question_type === 'newsletter_content') {
                dispatch({ type: GET_NEWSLETTER_CONTENT, payload: res.data.data });
            } else {
                dispatch({ type: GET_ALL_FAQS, payload: res.data.data });
            }
        }
    } catch (error) {
        console.log(error);
        // check_token_expired_logout(error);
    }
};

export const get_buying_content = async (token, content_type,dispatch) => {
    try {
        const url = `${REACT_APP_BASE_URL}admin/list-content/${content_type}`;
        const HEADERS = {
            headers: {
                'x-access-token': token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_BUYING_CONTENT, payload: res.data.data });
           return res.data.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const get_selling_content = async (token, content_type,dispatch) => {
    try {
        const url = `${REACT_APP_BASE_URL}admin/list-content/${content_type}`;
        const HEADERS = {
            headers: {
                'x-access-token': token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_SELLING_CONTENT, payload: res.data.data });
           return res.data.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const get_finalizing_content = async (token, content_type,dispatch) => {
    try {
        const url = `${REACT_APP_BASE_URL}admin/list-content/${content_type}`;
        const HEADERS = {
            headers: {
                'x-access-token': token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_FINALIZE_CONTENT, payload: res.data.data });
           return res.data.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const update_items_sequence = async (token,sequencearr,question_type,dispatch) => {
    try {
        const url = `${REACT_APP_BASE_URL}admin/update-sequence/${question_type}`;
        const HEADERS = {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            }
        };
        const res = await axios.put(url,JSON.stringify(sequencearr),HEADERS);
        if(res.data && res.data.status){
           toast.success(res.data.message);
           return true;
        } else {
            return false;
        }
    } catch (error) {
        toast.error(error.response.data.error);
    }
};

export const get_contact_us_content = async (token,content_type,dispatch) => {
    try {
        const url = `${REACT_APP_BASE_URL}admin/list-content/${content_type}`;
        const HEADERS = {
            headers: {
                'x-access-token': token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_CONTACT_US_CONTENT, payload: res.data.data });
        }
    } catch (error) {
        toast.error(error.response.data.error);
    }
};

export const get_sell_item_content = async (token,content_type,dispatch) => {
    try {
        const url = `${REACT_APP_BASE_URL}admin/list-content/${content_type}`;
        const HEADERS = {
            headers: {
                'x-access-token': token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_SELL_ITEM_CONTENT, payload: res.data.data });
        } 
    } catch (error) {
        toast.error(error.response.data.error);
    }
};