import { Country, State } from "country-state-city";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

const { REACT_APP_BASE_URL } = process.env;


const ViewDetail = ({ show, handleClose, edititem }) => {
    const [formdata, setFormData] = useState({
        fullname: '',
        phonenumber: '',
        additionalfees: '',
        storename: '',
        storewebsite: '',
        range: '',
        storelegaldoc: null,
        itemname: '',
        iteminfo: '',
        versiontype: '',
        condition: '',
        knowsflaws: '',
        itemlocation: '',
        zipcode: '',
        higlights: '',
        modificationinfo: '',
        servicehistory: '',
        ownercountry: '',
        ownerstate: '',
        sellernotes: '',
        reserveprice: '',
        mediaimage: [],
        mediavideo: [],
        bidstart: '',
        bidend: ''
    });
    const versiontypes = [
        { id: 1, name: 'Countertop', value: 'Countertop' },
        { id: 2, name: 'Full size', value: 'Full_size' }
    ];
    const conditions = [
        { id: 1, name: 'Original', value: 'Original' },
        { id: 2, name: 'Restored', value: 'Restored' }
    ];
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        if (edititem) {
            const startdate = new Date(edititem?.start_date);
            const enddate = new Date(edititem?.end_date);
            setImages(edititem?.photos?.map((path) => {
                return { preview: REACT_APP_BASE_URL + path, imgdata: path, }
            }));
            setVideos(edititem?.video?.map((item) => item));
            setFormData(prev => {
                return ({
                    ...prev,
                    fullname: edititem?.owner?.full_name,
                    phonenumber: edititem?.owner?.phone_number,
                    additionalfees: edititem?.owner?.additional_fees ? parseInt(edititem?.owner?.additional_fees) : null,
                    storename: edititem?.owner?.store_name,
                    storewebsite: edititem?.owner?.store_website,
                    range: edititem?.owner?.range ? parseInt(edititem?.owner?.range) : null,
                    storelegaldoc: edititem?.owner?.store_legal_docs?.length > 0 ? edititem?.owner?.store_legal_docs : null,
                    itemname: edititem?.item_name !== "null" ? edititem?.item_name : null,
                    iteminfo: edititem?.game_info !== "null" ? edititem?.game_info : null,
                    versiontype: edititem?.version_type !== "null" ? edititem?.version_type : null,
                    condition: edititem?.condition !== "null" ? edititem?.condition : null,
                    knowsflaws: edititem?.known_flaws !== "null" ? edititem?.known_flaws : null,
                    itemlocation: edititem?.location !== "null" ? edititem?.location : null,
                    zipcode: edititem?.zipcode !== "null" ? edititem?.zipcode : null,
                    higlights: edititem?.highlights_info !== "null" ? edititem?.highlights_info : null,
                    modificationinfo: edititem?.modifications_info !== "null" ? edititem?.modifications_info : null ,
                    servicehistory: edititem?.service_history !== "null" ? edititem?.service_history : null,
                    ownercountry: edititem?.owner_country !== "null" ? edititem?.owner_country : null,
                    ownerstate: edititem?.owner_state !== "null" ? edititem?.owner_state : null,
                    sellernotes: edititem?.seller_note !== "null" ? edititem?.seller_note : null,
                    reserveprice: edititem?.reserver_price ? parseInt(edititem?.reserver_price) : '',
                    bidstart: edititem?.start_date ? startdate.toISOString().split('T')[0] : '',
                    bidend: edititem?.end_date ? enddate.toISOString().split('T')[0] : ''
                })
            });
        }
    }, [edititem]);

    const handleDownload = async (url) => {
        // const filepath = url.includes('+') ? url.replace(/\+/g, '%2B') : url;
        const fileContent = `${REACT_APP_BASE_URL}download?file=${url}`;
        const fileName = url?.substring(url?.lastIndexOf("/") + 1);
        const getMimeType = (ext) => {
            const mimeTypes = {
                txt: "text/plain",
                pdf: "application/pdf",
                zip: "application/zip",
                jpg: "image/jpeg",
                jpeg: "image/jpeg",
                png: "image/png",
                gif: "image/gif",
                mp4: "video/mp4",
                mov: "video/quicktime"
            };
            return mimeTypes[ext] || "application/octet-stream";
        };

        const response = await fetch(fileContent);
        const blobFile = await response.blob();
        const fileExtension = fileName?.split(".").pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);
        const blobwithtype = new Blob([blobFile], { type: mimeType });
        saveAs(blobwithtype, fileName);
    };

    return (
        <Modal show={show} onHide={handleClose} className="modal-lg">
            <Modal.Body>
                <div>
                    <div className="col-md-12 col-lg-12 text-end">
                        <i class="fa-solid fa-xmark color-white cursor-pointer" onClick={handleClose}></i>
                    </div>
                    <div className="profile-deatils bg-white mt-4">
                        <div className="detail-heading px-4 py-3"><div className="text-dark fw-600 h5 mb-0">Item Details</div> </div>
                        <div className="p-4">
                            <div className="row">
                                <form>
                                    <div>
                                        <h4 className="text-black mb-3 fw-600 fw-600">Owner Details</h4>
                                    </div>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                <label className="text-dark fw-600 mb-2">Full Name</label>
                                                <div className="password position-relative">
                                                    <input type="text" name="fullname" className="form-control" disabled placeholder="Full Name" value={formdata?.fullname} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <label className="text-dark fw-600 mb-2">Phone Number</label>
                                                <div className="password position-relative">
                                                    <input type="number" name="phonenumber" className="form-control" disabled placeholder="Phone Number" value={formdata?.phonenumber} />
                                                </div>
                                            </div>
                                            {formdata?.additionalfees && <div className="col-md-6 col-lg-6">
                                                <label className="text-dark fw-600 mb-2">Additional Fees</label>
                                                <div className="password position-relative">
                                                    <input type="text" name="fullname" className="form-control" disabled placeholder="Full Name" value={formdata?.additionalfees} />
                                                </div>
                                            </div>}
                                            {formdata?.storename && <div className="col-md-6 col-lg-6">
                                                <label className="text-dark fw-600 mb-2">Store Name</label>
                                                <div className="password position-relative">
                                                    <input type="text" name="fullname" className="form-control" disabled placeholder="Full Name" value={formdata?.storename} />
                                                </div>
                                            </div>}
                                            {formdata?.storewebsite && <div className="col-md-6 col-lg-6">
                                                <label className="text-dark fw-600 mb-2">Store Website</label>
                                                <div className="password position-relative">
                                                    <input type="text" name="fullname" className="form-control" disabled placeholder="Full Name" value={formdata?.storewebsite} />
                                                </div>
                                            </div>}
                                            {formdata?.range && <div className="col-md-6 col-lg-6">
                                                <label className="text-dark fw-600 mb-2">Range of Items you deal</label>
                                                <div className="password position-relative">
                                                    <input type="text" name="fullname" className="form-control" disabled placeholder="Full Name" value={formdata?.range} />
                                                </div>
                                            </div>}
                                            {formdata?.storelegaldoc !== null && <div className="col-md-12 col-lg-12">
                                                <label className="text-dark fw-600 mb-2">Store Legal Document</label>
                                                <div className="password position-relative">
                                                    {formdata?.storelegaldoc?.map((item) => (
                                                        <Link className="cursor-pointer" href={`${REACT_APP_BASE_URL}${item}`} style={{ color: "#0d6efd" }} onClick={() => handleDownload(item)}>
                                                            {item} <br/>
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <h4 className="text-black mb-3 fw-600 fw-600">Item Details</h4>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Item Name</label>
                                                    <div className="password position-relative">
                                                        <input type="text" name="itemname" className="form-control" disabled placeholder="Item Name" value={formdata?.itemname} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Item Information</label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="iteminfo"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your item information"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.iteminfo}
                                                            disabled
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6">
                                                    <label className="text-dark fw-600 mb-2">Version Type</label>
                                                    <div className="password position-relative">
                                                        <select name="versiontype" type="select" className="form-control" disabled value={formdata?.versiontype}>
                                                            <option value='' disabled>Select</option>
                                                            {versiontypes?.map((item, index) => (
                                                                <option key={index} value={item?.label}>{item?.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <label className="text-dark fw-600 mb-2">Condition</label>
                                                    <div className="password position-relative">
                                                        <select name="condition" type="select" className="form-control" disabled value={formdata?.condition}>
                                                            <option value='' disabled>Select</option>
                                                            {conditions?.map((item, index) => (
                                                                <option key={index} value={item?.label}>{item?.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Known Flaws (Optional)</label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="knowsflaws"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your flaws"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.knowsflaws}
                                                            disabled
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Item Location</label>
                                                    <div className="password position-relative">
                                                        <input type="text" name="itemlocation" className="form-control" disabled placeholder="location" value={formdata?.itemlocation} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Zip Code</label>
                                                    <div className="password position-relative">
                                                        <input type="number" name="itemlocation" className="form-control" disabled placeholder="location" value={formdata?.zipcode} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Highlights Info</label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="higlights"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your highlight info"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.higlights}
                                                            disabled
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Modifications Info</label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="modificationinfo"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your modifications info"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.modificationinfo}
                                                            disabled
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Service History</label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="servicehistory"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your Service History"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.servicehistory}
                                                            disabled
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="text-dark fw-600 mb-2">Ownership Info</label>
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="password position-relative">
                                                        <input type="text" name="ownercountry" className="form-control" disabled placeholder="" value={formdata?.ownercountry} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="password position-relative">
                                                        <input type="text" name="ownerstate" className="form-control" disabled placeholder="" value={formdata?.ownerstate} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Seller Notes</label>
                                                    <div className="password position-relative">
                                                        <textarea
                                                            name="sellernotes"
                                                            cols="4"
                                                            rows="4"
                                                            class="form-control b-0 text-area-height"
                                                            placeholder="Enter Your Seller Notes"
                                                            style={{ height: '100px' }}
                                                            value={formdata?.sellernotes}
                                                            disabled
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-2 col-lg-2">
                                                    <label className="text-dark fw-600 mb-2">Reserve Price</label>
                                                    <div className="password position-relative">
                                                        <input type="number" name="reserveprice" className="form-control" disabled placeholder="" value={formdata?.reserveprice} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h4 className="text-black mb-2 fw-600">Media Data</h4>
                                        <div className="mb-4">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Image</label>
                                                    <div className="row mt-3">
                                                        {images?.map((item, index) => (
                                                            <div key={index} className="col-md-2">
                                                                <div className=" position-relative pb-3 d-flex" >
                                                                    <img src={item.preview}
                                                                        // className="img-fluid object-fit-cover rounded" 
                                                                        height={100}
                                                                    // width={'100%'} 
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" mb-3">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <label className="text-dark fw-600 mb-2">Video</label>
                                                    <div className="row mt-3">
                                                        {videos?.map((item, index) => (
                                                            <div key={index} className="col-md-2">
                                                                <div className=" position-relative pb-3 d-flex">
                                                                    <video src={`${REACT_APP_BASE_URL}${item}`} height={100}
                                                                        // style={{ height: '100%', width: '100%' }} 
                                                                        controls />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="text-black mb-3 fw-600">Bid Timing</h4>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-4">
                                                    <div className="password position-relative">
                                                        <label className="text-dark fw-600 mb-1">Start Date</label>
                                                        <input type="date" name="bidstart" className="form-control " disabled value={formdata?.bidstart} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-4">
                                                    <div className="password position-relative">
                                                        <label className="text-dark fw-600 mb-1">End Date</label>
                                                        <input type="date" name="bidend" className="form-control " disabled value={formdata?.bidend} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
};

const mapStateToProps = (state) => {
    return {
        edititem: state.user.itemdetail
    }
};

export default connect(mapStateToProps)(ViewDetail);
