import { GET_ALL_FAQS, GET_BUYING_CONTENT, GET_CONTACT_US_CONTENT, GET_EDIT_DATA, GET_FINALIZE_CONTENT, GET_INSERTBID_CONTENT, GET_NEWSLETTER_CONTENT, GET_SELL_ITEM_CONTENT, GET_SELLING_CONTENT } from "./cmsTypes";


const initialState = {
    faqItems: [],
    totalFaqs: 0,
    editData: null,
    insertbid_content: [],
    newsletter_content: [],
    buyingCont: [],
    sellingCont: [],
    finalizeCont: [],
    contactCont: [],
    sellItemCont: []
};

const cmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_FAQS:
            return {
                ...state,
                faqItems: action.payload,
            };
        case GET_EDIT_DATA:
            return {
                ...state,
                editData: action.payload,
            };
        case GET_INSERTBID_CONTENT:
            return {
                ...state,
                insertbid_content: action.payload,
            };
        case GET_NEWSLETTER_CONTENT:
            return {
                ...state,
                newsletter_content: action.payload,
            };
        case GET_BUYING_CONTENT:
            return {
                ...state,
                buyingCont: action.payload,
            };
        case GET_SELLING_CONTENT:
            return {
                ...state,
                sellingCont: action.payload,
            };
        case GET_FINALIZE_CONTENT:
            return {
                ...state,
                finalizeCont: action.payload,
            };
        case GET_CONTACT_US_CONTENT:
            return {
                ...state,
                contactCont: action.payload,
            };
        case GET_SELL_ITEM_CONTENT:
            return {
                ...state,
                sellItemCont: action.payload,
            };

        default:
            return state;
    }
};

export default cmsReducer;

