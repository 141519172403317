import React, { useEffect, useState } from "react";
import { Button, Form, FormLabel } from "react-bootstrap";
import { FormControl } from '@mui/joy';
import ReactQuill from "react-quill";
import { create_faq_question, get_all_faq_questions } from "../../reduxData/cms/cmsAction";
import { connect, useDispatch } from "react-redux";

const Policy = ({user, faqItems}) => {
    const [formData, setFormData] = useState({
       content: '',
        status: false,
    });

    const [errors, setErrors] = useState({
        content: '',
    });
    const dispatch = useDispatch();

    const modules = {
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video'],
        ]
    };

    const handleQuillChange = (value) => {
        setErrors({ ...errors, content: !value || value === '<p><br></p>' ? "Content is required" : null });
        setFormData({ ...formData, content: value });
    };

    const handleStatus = (e) => {
        const { checked } = e.target;
        if(checked){
            setFormData({ ...formData, status: true });
        } else {
            setFormData({ ...formData, status: false });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { content } = formData;
        setErrors({
            ...errors,
            content: !content || content === '<p><br></p>' ? "Content is required" : null,
        });
        if(content){
            const quest_data = {
                name: "privacy_policy",
                type: "privacy_policy",
                status : formData.status,
                content : {
                    Content: formData.content,
                }
            };
        await create_faq_question(localStorage.getItem("token"),quest_data,faqItems[0]?._id);
        await get_all_faq_questions(localStorage.getItem("token"), "privacy_policy", dispatch);
        }
    };

    useEffect(() => {
        const handleQuest = async () => {
            await get_all_faq_questions(localStorage.getItem("token"), "privacy_policy", dispatch);
        };
        handleQuest();
    }, []);

    useEffect(() => {
       if(faqItems){
         setFormData({
            content: faqItems[0]?.content?.Content,
            status: faqItems[0]?.status,
         });
       }
    },[faqItems]);

    return (
        <div className="mt-1">
            <div className="container">
                <h3 className="mb-0 fw-600">Privacy Policy</h3>
                <Form onSubmit={handleSubmit}>
                    <FormControl className="mt-2 mb-2">
                        <FormLabel className="fw-600">Content</FormLabel>
                        <ReactQuill
                            name="answer"
                            value={formData.content}
                            modules={modules}
                            onChange={handleQuillChange}
                        />
                        {errors?.content && <div className="error_msg">{errors?.content}</div>}
                    </FormControl>
                    <FormControl className="mt-1 mb-2">
                        <FormLabel className="fw-600">Status</FormLabel>
                        <div class="form-check form-switch switch-large">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={formData.status} onChange={handleStatus} />
                        </div>
                    </FormControl>
                    <Button type="submit" className="btn common-button">Save</Button>
                </Form>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        faqItems: state.cms.faqItems,
    }
};

export default connect(mapStateToProps)(Policy);
